import React from "react";
import Modal from "../../Modal";
import Vote from "../";

import { connect } from "react-redux";
import { hideModal } from "../../../actions";

// check heroicon
import { CheckCircleIcon } from '@heroicons/react/24/outline';

class VoteModal extends React.Component {

    onClose = (e) => {
        e.preventDefault();
        this.props.hideModal();
    }

    renderConfirmationContent = () => { 
        return (
            <div className='gap-4 flex flex-col items-center justify-center text-lg'>
                <CheckCircleIcon className='h-16 w-16 text-purple-700' />
                <p>Thank you for verifying your vote!</p>
                <p>To edit your vote or participate further on this proposal, please login.</p>
            </div>
        );
    }

    render() {
        if (!this.props.modal || !this.props.modal.visible || this.props.modal.modal.indexOf('vote') === -1) {
            return null;
        }

        // get vote type from modalType after 'vote'
        const voteType = this.props.modal.modal.split('-')[1];

        // if vote type is 'confirmation', then render the confirmation modal
        if (voteType === 'confirmation') {
            return (
                <Modal
                    title="Thank you!"
                    content={this.renderConfirmationContent()}
                    onDismiss={this.onClose}
                />
            );
        }

        return (
            <Modal
                title={voteType && voteType === 'oppose' ? "Oppose this proposal" : "Support this proposal"}
                content={<Vote type={voteType} postGuestVote={this.props.postGuestVote} proposal={this.props.proposal} />}
                onDismiss={this.onClose}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        modal: state.modal
    }
}

export default connect(mapStateToProps, { hideModal })(VoteModal);