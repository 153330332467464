import React from "react";
import { Route, Routes } from 'react-router-dom';

import Proposal from "./view";

import ScrollToTop from "../../utils/scrollToTop";

const ProposalRouter = (props) => {

    return (
        <>
            <ScrollToTop />
            <Routes basename="/proposal">
                <Route path="/:id" element={<Proposal />}/>
                <Route path="/:id/vote/verify/:token" element={<Proposal />}/>
            </Routes>
        </>
    )
}

export default ProposalRouter;