import React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const Addresspicker = ({input, label, meta, placeholder}) => {
    // const { options, value } = this.props;
    // const selectedOption = options.find(option => option.value === value)

    // if (!input || !input.value) {
    //     return <div>Loading..</div>
    // }

    const className = `field ${meta.error && meta.touched ? 'error': ''}`
    return (
        <div className={className}>
            <label>{label}</label>
            <GooglePlacesAutocomplete
                selectProps={{ 
                    placeholder: 'Search Places...', 
                    value: { label: input.value, value: 0 },
                    onChange: (e)=>input.onChange(e.label)

                }}
                debounce={500}
                apiKey="AIzaSyCtEaHvujlHdZFR9TYxRhRTE3e3VwzVaBE"
                autocompletionRequest={{
                    componentRestrictions: {
                        country: ['US']
                    },
                    types:['address']
                }}
            />

            {meta.error && meta.touched && 
                <span className="text-danger small block">{meta.error}</span>}
        </div>
    )
}

export default Addresspicker;