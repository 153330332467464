import { 
    AUTH_USER, 
    LOG_OUT, 
    SET_USER_PARTISAN, 
    FETCH_AUTH_PARTISANS,
    ADD_AUTH_PARTISAN
} from '../actions/types';

const INITIAL_STATE = {
    userId: null,
    email: null,
    name: null,
    permission: null,
    credits: null,
    partisan: null,
    partisanList: [],
    identity: null,
    alias: null
}

const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AUTH_USER:
            return { 
                ...state, 
                userId: action.payload._id,
                name: action.payload.name,
                email: action.payload.email,
                permission: action.payload.permission,
                credits: action.payload.credits,
                partisan: action.payload._partisan,
                identity: action.payload._identity,
                alias: action.payload.alias
            };
        case LOG_OUT:
            return {
                ...state,
                userId: null,
                email: null,
                name: null,
                permissions: null,
                credits: null,
                partisan: null,
                partisanList: [],
                identity: null,
                alias: null
            }
        case SET_USER_PARTISAN:
            return {
                ...state,
                partisan: action.payload._id,
                identity: action.payload.identity,
                alias: action.payload.alias
            }
        case FETCH_AUTH_PARTISANS:
            return {
                ...state,
                partisanList: action.payload.partisans,
                identity: action.payload.identity
            }
        case ADD_AUTH_PARTISAN:
            return {
                ...state,
                partisanList: [...state.partisanList, action.payload]
            }
        default:
            return state;
    }
};

export default authReducer;