import React from "react";
import Galea from "../../../utils/Galea";
import RecordEvent from "../../analytics/pageview";

const HowThisWorks = () => {

    return (
        <>
        <Galea 
            title="Free political campaign page - Speeqr"
            description="Free political campaign page on Speeqr.org"
            url={`${process.env.REACT_APP_DOMAIN}/free-campaign-page`}
        />
        <RecordEvent 
            hitType='pageview' 
            page={window.location.pathname} 
            title='Free Campaign Page'
        />
        <div className="flex flex-row items-center justify-center mt-5 mb-10 px-5">
            <div className="page">
                <h1>Free political campaign page</h1>
                <p>
                    Welcome! To get started either claim your profile or create a new one. Once you have a profile, you can create proposals and organize them into sets of priorities.
                </p>
                <p>
                    Share the link to your profile with your constituents and supporters to get feedback on your proposals and to build support for your campaign.
                </p>
                <p>
                    We're just getting started, so if you have any questions or feedback, please email us at hello@speeqr.org
                </p>
            </div>
        </div>
        </>
    );

}

export default HowThisWorks;
