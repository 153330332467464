import React from 'react';

const Identity = () => {
    return (
        <div className="logo text-3xl">
            <span>S</span>
        </div>
    );
}

export default Identity;