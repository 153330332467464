import React from "react";
import { useState, useEffect } from "react";

import Loader from "../../Loader";
import PostCard from "../../post/postCard";
import PropCard from "../../proposal/propCard";

import { connect } from "react-redux";
import {  
    fetchProposals, 
    fetchProposalsByAddress, 
    showModal,
    fetchFeed
} from "../../../actions";

import { Link } from "react-router-dom";

import { Form, Field } from "react-final-form";

import { LightBulbIcon } from '@heroicons/react/24/outline';

import renderSearchInput from "../../forms/CustomSearchInput";

import Galea from "../../../utils/Galea";
import RecordEvent from "../../analytics/pageview";

const Landing = ({fetchProposals, fetchProposalsByAddress, showModal, loader, proposals, feed, fetchFeed}) => {

    const renderPusher = () => {
        const className = `pusher ${!loader.pusher && 'hide-up'}`
        return(
            <div className={className}>
                <div className="flex flex-row justify-center gap-8 mx-5 my-10 sm:h-[30vh]">
                    <div className="flex flex-col md:w-5/12 space-y-2 justify-center">
                        <h1 className="text-5xl font-bold text-purple-600">Welcome to Speeqr</h1>
                        <h2 className="text-3xl font-semibold">A platform for mobilizing
                        democratic action
                        and better governance.</h2>
                    </div>
                    <div className="flex-col justify-center items-center hidden sm:flex sm:w-3/12 bg-gradient-to-tl from-transparent from-30% via-55% via-yellow-50 to-65% to-transparent rounded-full">
                        <LightBulbIcon className="w-28 h-28 text-yellow-400" />
                    </div>
                </div>
            </div>
        )
    }

    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        if (!mounted) {
            fetchFeed({address: ''});
            setMounted(true);
        }
    }, [mounted, fetchFeed]);

    
    const showSignInModal = (e) => {
        // prevent default behavior
        e.preventDefault();
        showModal('login')
    }

    const onSubmit = (formValues) => {
        fetchFeed({address: formValues.address});
    }
    
    const renderSearch = () => {
        return(
            <Form 
                onSubmit={onSubmit}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className="ui form">
                        <Field 
                            name="address" 
                            component={renderSearchInput} 
                            type="text" 
                            placeholder="Enter ZIP code or Hometown, State" 
                            searchAction={handleSubmit}
                            />
                    </form>
                )}
            />
        )
    }

    return(
        <>
        <Galea 
            title="Speeqr - a petitioning system for better governance"
            description="A platform for mobilizing democratic action and better governance."
            url={process.env.REACT_APP_DOMAIN}
        />
        <RecordEvent hitType='pageview' page={window.location.pathname} title='Landing' />
            {renderPusher()}
        <div className="article">
            <div className="ui stackable grid">
                <div className="row centered">
                    <div className="ten wide column">
                        <h3>What's happening in your world?</h3>
                        {renderSearch()}
                        <p style={{marginTop: ".3em"}}>
                            <Link to="/login" onClick={showSignInModal} className="text-purple-600 font-bold">Log-in</Link> to save 
                            your location preference and contribute to proposals.</p>
                        <h3>Here's a selection of proposals:</h3>
                    </div>
                </div>
                <div className="row centered">
                    <div className="ten wide column">
                        {
                        feed?.items ? Object.values(feed.items).map((item, i) => {
                            if (item.type === 'post') {
                                return(
                                    <PostCard 
                                        key={i}
                                        post={item}
                                        isSignedIn
                                    />
                                )
                            } else if (item.type === 'proposal') {
                                return(
                                    <PropCard 
                                        key={i}
                                        proposal={item}
                                        loader={loader}
                                        isSignedIn
                                    />
                                )
                            } else {
                                return null;
                            }
                        }) : <Loader loader='feed'/>
                    }
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        proposals: state.proposals,
        feed: state.feed,
        loader: state.loader
    }
}

export default connect(mapStateToProps, {
    fetchProposals, 
    fetchProposalsByAddress,
    fetchFeed,
    showModal
})(Landing); 