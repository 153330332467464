import React, { useEffect, useState } from "react";

// import heroicon of back arrow
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';

import { TailSpin } from "react-loader-spinner";

// input is the destructured version of formProps.input

function renderError({error, touched}) {
    if (touched && error) {
        return (
            <>
            <div className="absolute top-8 right-4 flex items-center text-red-500">
                <ExclamationTriangleIcon className="h-8 w-8 mr-1" />
            </div>
            <div className="text-white bg-red-400 w-fit flex-nowrap py-2 px-4 mt-2 rounded whitespace-nowrap">
                {error}
            </div>
            </>
        );
    }
}

const SearchInput = ({input, label, meta, placeholder, disabled, handleClick, searchAction}) => {

    const [term, setTerm] = useState('');
    const [loading, setLoading] = useState(false);

    // spinning loader
    const loader = (
        <TailSpin
            color="#00BFFF"
            height={24}
            width={24}
            timeout={4000} //4 secs
        />
    );

    const className = `input input-bordered rounded-full w-full ${meta.error && meta.touched ? 'input-error': ''}`;
    const divClassName = `relative w-full`;

    const searchIcon = (
        <div 
            onClick={handleClick} 
            className="absolute top-3 right-4 flex text-gray-500 cursor-pointer">
            {loading ? loader : <MagnifyingGlassIcon className="h-6 w-6 text-purple-600 mr-1" />}
        </div>
    );

    useEffect(() => {
        const search = () => searchAction(input.value);
        setLoading(true);

        if (term && term.length > 2 && term !== input.value) {
            const timeoutId = setTimeout(() => {
                // if term is not in options, search
                if (input.value && input.value.length > 2 && term !== input.value) {
                    setTerm(input.value);
                    search();
                }
                setLoading(false);
            }, 500);
            // react will run the return when component is re-reendered.
            return () => {
                setLoading(false);
                clearTimeout(timeoutId);
            }
        } else {
            setTerm(input.value);
            setLoading(false);
        }

    }, [term, input, searchAction]);

    return (
        <div className={divClassName}>
            <label htmlFor={input.name}>{label}</label>
            <input
                // onChange={formProps.input.onChange}
                // value={formProps.value}
                // is the same as
                {...input} 
                id={input.name}
                autoComplete="off"
                placeholder={placeholder}
                disabled={disabled}
                className={className}
            />
            {searchIcon}
            {renderError(meta)}
        </div>
        );
}

export default SearchInput;