import React from "react";
import ReactDOM from "react-dom";

import { XMarkIcon } from "@heroicons/react/24/solid";

const Modal = props => {
    return ReactDOM.createPortal(
        <div onClick={props.onDismiss} className="modal modal-open">
            <div className="modal-box sm:w-7/12 sm:max-w-none" onClick={(e) => e.stopPropagation()}>
                <div className="justify-between flex flex-row items-center mb-3 -mt-2">
                    <h2>{props.title}</h2>
                    <button onClick={props.onDismiss} className="btn btn-square btn-ghost">
                        <XMarkIcon className="h-6 w-6" />
                    </button>
                </div>
                <div>
                    {props.content}
                </div>
                <div className="modal-action">
                    <div className="actions">
                        {props.actions}
                    </div>
                </div>
            </div>
        </div>,
        document.querySelector('#modal')
    );
};

export default Modal;