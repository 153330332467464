import _ from 'lodash';

import React from 'react';
import Modal from '../../Modal';

import { connect } from 'react-redux';

import { Form, Field } from 'react-final-form';
import CustomInput from '../../forms/CustomInput';

import { 
    getPartisanPlatforms, 
    updatePlatform, 
    addProposalToPlatform, 
    removeProposalFromPlatform, 
    deletePlatform,
    showModal, 
    hideModal 
} from '../../../actions';

// check heroicon 
import { PlusCircleIcon, TrashIcon, PencilIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

const ManagePlatforms = (
    {
        partisan: partisanId, 
        platformList, 
        modal, 
        getPartisanPlatforms, 
        updatePlatform, 
        addProposalToPlatform, 
        removeProposalFromPlatform,
        deletePlatform,
        hideModal
    }) => {

    const [mounted, setMounted] = React.useState(false);
    const [showForm, setShowForm] = React.useState(false);

    const proposalId = modal?.modal?.split('-')[1];

    React.useEffect(() => {
        if (!mounted && platformList?.length === 0) {
            setMounted(true);
            getPartisanPlatforms(partisanId);
        }
    }, [getPartisanPlatforms, mounted, platformList, partisanId]);

    // don't render if no partisans
    if (!partisanId) {
        return null;
    }

    const editPlatform = (platformId) => {
        console.log('edit platform', partisanId, platformId);
    }

    const handleDeletePlatform = (platformId) => {
        // confirm delete
        const confirmDelete = window.confirm('Are you sure you want to delete this priority?');

        if (!confirmDelete) {
            return;
        }

        deletePlatform(partisanId, platformId);
    }

    const handleProposalChange = (platformId, proposalInPlatform) => {
        if (proposalInPlatform) {
            removeProposalFromPlatform(partisanId, platformId, proposalId);
        } else {
            addProposalToPlatform(partisanId, platformId, proposalId);
        }

    }

    const renderPlatforms = () => {
        if (_.isEmpty(platformList)) {
            return null;
        }

        if (platformList) {
            return platformList.map(platform => {

                // check if proposal is in platform
                const proposalInPlatform = platform.proposals.find(proposal => proposal === proposalId || proposal?._id === proposalId);

                return (
                    <div 
                        key={platform._id} 
                        className="flex flex-row justify-between items-center border-b border-gray-200 py-2"
                        >
                        <div className="flex flex-row justify-between w-full gap-2">
                            <button 
                                className={`btn flex-grow gap-2 
                                    ${proposalInPlatform ? 'btn-primary' : 'btn-outline'}
                                    `}
                                onClick={() => handleProposalChange(platform._id, proposalInPlatform)}
                                >
                                    {proposalInPlatform && <CheckCircleIcon className="h-7 w-7" />}
                                    {platform.title}
                            </button>
                            <button 
                                className="btn btn-square btn-ghost"
                                onClick={() => editPlatform(platform._id)}
                                disabled={true}
                                >
                                <PencilIcon className="h-5 w-5 text-gray-500" />
                            </button>
                            <button 
                                className="btn btn-square btn-ghost"
                                onClick={() => handleDeletePlatform(platform._id)}
                                >
                                <TrashIcon className="h-5 w-5 text-gray-500" />
                            </button>
                            {/* <div className="text-base">{platform.description}</div> */}
                        </div>
                    </div>
                );
            });
        }
    }

    const handleAddPlatformSubmit = (values) => {
        updatePlatform(partisanId, values);
        setShowForm(false);
    }

    const renderAddPlatformForm = () => {
        const initialValues = {
            title: '',
            description: '',
            id: '_new_'
        }
        return (
            <Form
                onSubmit={handleAddPlatformSubmit}
                initialValues={initialValues}
                validate={formValues => {
                    const errors = {};

                    if (!formValues.title) {
                        errors.title = 'A name is required';
                    }

                    if (!formValues.description) {
                        errors.description = 'A description is required';
                    }

                    return errors;
                }}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit} className='w-full'>
                        <div className='flex flex-row w-full gap-2'>
                            <div>
                            <Field 
                                name="title" 
                                component={CustomInput} 
                                placeholder="Name of priority" 
                                size="input-sm" 
                                autoFocus 
                            />
                            </div>
                            <div className='flex-grow'>
                            <Field 
                                name="description" 
                                component={CustomInput} 
                                placeholder="Description" 
                                size="input-sm" 
                            />
                            </div>
                            <div>
                            <Field
                                name='id'
                                component='input'
                                type='hidden'
                            />
                            <button 
                                type="submit" 
                                disabled={submitting || pristine} 
                                className="ml-2 btn btn-primary btn-sm self-end">
                                Next
                            </button>
                            </div>
                        </div>
                    </form>
                )}
            />
        );
    }

    const renderAddPlatform = () => {
        return (
            <div className="flex flex-row justify-between items-center border-b border-gray-200 py-2 w-full">
                {showForm ? renderAddPlatformForm() : (
                    <div className="flex flex-row items-center justify-center cursor-pointer" onClick={handleAddPlatform}>
                        <div className='w-9'>
                            <PlusCircleIcon className="h-7 w-7 text-gray-500" />
                        </div>
                        <div>
                            <div className="text-base">Add Priority</div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    const handleAddPlatform = async () => {
        setShowForm(true);
    }

    const renderContent = () => {
        return (
            <div className="flex flex-col">
                <div className="flex flex-col">
                    <div className="flex flex-row justify-between">
                        <div className="flex flex-col gap-2">
                            <h3 className='text-lg'>Your priorities are how you define the issues that matter and how you organize your proposals.</h3>
                            <div className="text-sm">Add a priority to save this proposal. You can add anyone's proposal to your priorities.</div>
                        </div>
                    </div>
                    <div className="flex flex-col mt-4">
                        <div className="flex flex-col mt-2">
                            {renderPlatforms()}
                            {renderAddPlatform()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (!modal.visible || modal.modal.indexOf('platformProposal') === -1) {
        return null;
    }

    return (
        <Modal
            title="Define your priorities"
            content={renderContent()}
            onDismiss={() => hideModal()}
        />
    );

}

const mapStateToProps = (state) => {

    const { partisan } = state.auth;

    const platformList = state.partisan[partisan]?.platforms;

    return {
        partisan: state.auth.partisan,
        platformList,
        modal: state.modal
    }
}

export default connect(mapStateToProps, 
    { 
        getPartisanPlatforms, 
        updatePlatform, 
        addProposalToPlatform, 
        removeProposalFromPlatform, 
        deletePlatform,
        showModal, 
        hideModal 
    })(ManagePlatforms);