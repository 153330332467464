import React from "react";
// import DOMPurify from "dompurify";

import "./ReadMore.css";

const ReadMore = ({ children, maxCharacterCount = 100 }) => {

    // const cleanChildren = DOMPurify.sanitize(children, {
    //     ALLOWED_TAGS: ["b", "i", "em", "strong", "p", "br", "ul", "li", "ol", "blockquote"]
    // });

    const text = children;
    const [isTruncated, setIsTruncated] = React.useState(true);

    const resultString = text?.length>0 && isTruncated ? `${text.slice(0, maxCharacterCount)}...` : text;

    function toggleIsTruncated() {
        setIsTruncated(!isTruncated);
    }

    // if text is short enough, don't show the "read more" link
    if (!children || children.length <= maxCharacterCount) {
        return <div dangerouslySetInnerHTML={{ __html: text }} />;
    }

    return (
        <div className={`text-gray-500 read-more`}>
            <span dangerouslySetInnerHTML={{ __html: resultString }} />
            <span onClick={toggleIsTruncated} className={`cursor-pointer float-left`}>
                {isTruncated ? " ... read more" : ""}
            </span>
        </div>
    );
};

export default ReadMore;
