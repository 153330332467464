import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import Feed from "./feed";
import Landing from "./landing";
import EditPartisan from "../partisan/edit";
import Loader from "../Loader";

const Home = ({auth, partisan, loader}) => {

    if (auth?.userId && auth?.partisan && partisan && partisan[auth.partisan] && !_.isEmpty(partisan[auth.partisan].publics)) {
        return <Feed />
    } else if (!loader?.fetchPartisan && auth?.userId) {
        return <EditPartisan />
    } else if (auth?.permission === 'guest') {
        return <Landing />
    } else { 
        return <Loader />
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        partisan: state.partisan,
        loader: state.loader
    }
}

export default connect(mapStateToProps)(Home);