import _ from "lodash";
import { 
    FETCH_POSTS,
    FETCH_POST,
    UPDATE_POST,
    DELETE_POST,
} from '../actions/types';

const postReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_POSTS:
            return { ..._.mapKeys(action.payload, '_id') };
        case FETCH_POST:
            return { ...state, [action.payload._id]: action.payload }
        case UPDATE_POST:
            return { [action.payload._id]: action.payload, ...state }
        case DELETE_POST:
            return _.omit(state, action.payload);
        default:
            return state;
    }
};

export default postReducer;