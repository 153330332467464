import 'mapbox-gl/dist/mapbox-gl.css';

import React from 'react';
import { connect } from 'react-redux';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;

class ProposalMap extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            // lng: 5,
            // lat: 34,
            zoom: 15
        };
        this.mapContainer = React.createRef();
    }

    loadMap() {
        if (!this.props.proposal) {
            return;
        }
        const coordinates = this.props.proposal.coordinates.split(',');
        const latlng = {
            lat: parseFloat(coordinates[1]),
            lng: parseFloat(coordinates[0]),
          };
        
        const map = new mapboxgl.Map({
            container: this.mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: latlng,
            zoom: this.state.zoom,
            dragPan: false,
            scrollZoom: {around: 'center'}
        });

        this.map = map;

        const marker = new mapboxgl.Marker({
            draggable: false,
            color: '#314ccd'
        });

        map.on('load', () => {
            marker.setLngLat(latlng).addTo(map);
        }
        );
    }

    componentDidMount() {
        if (this.props.proposal && this.props.proposal.coordinates && this.props.proposal.coordinates.length && !this.map) {
            this.loadMap();
        }
    }

    componentDidUpdate() {
        if (this.props.proposal && this.props.proposal.coordinates && this.props.proposal.coordinates.length && !this.map) {
            this.loadMap();
        }
    };


    render() {

        if (!this.props.proposal || !this.props.proposal.coordinates || !this.props.proposal.coordinates.length) {
            return <div></div>;
        }

        return (
            <div className='map-container'>
                <div 
                    className='map' 
                    ref={this.mapContainer} 
                    style={{height: "200px", marginBottom: "10px"}}
                    />
            </div>
        );
    }
}

export default connect(null, null)(ProposalMap);