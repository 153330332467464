import "./index.css";

import React from "react";

import { Routes, Route } from "react-router-dom";

import HowThisWorks from "./how-this-works";
import FreeCampaignPage from "./free-campaign-page";
import ClaimProfile from "./claim-profile";

const PageRouter = () => {

    return (
        <Routes basename="/pages">
            <Route path="/how-this-works" element={<HowThisWorks />}/>
            <Route path="/free-campaign-page" element={<FreeCampaignPage />}/>
            <Route path="/claim-profile" element={<ClaimProfile />}/>
        </Routes>

    );
}

export default PageRouter;