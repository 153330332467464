import _ from 'lodash';
import React from 'react';
import { useState, useEffect } from 'react';
import { useParams, useMatch, Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import renderInput from '../../forms/CustomInput';
import renderTextarea from '../../forms/CustomTextarea';
import renderDropdown from '../../forms/CustomDropdown';
import LiveCheckInput from '../../forms/LiveCheckInput';
import { fetchPartisan, updatePartisan, searchAlias } from '../../../actions';

import RecordEvent from '../../analytics/pageview';

import { XMarkIcon } from '@heroicons/react/24/outline';

const ConditionalField = ({ when, isnot, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === isnot ? null : children)}
  </Field>
);

const EditPartisan = ({
  fetchPartisan,
  updatePartisan,
  search,
  partisans,
  electoralStatusOptions,
  partisanId,
  searchAlias,
}) => {
  const [mounted, setMounted] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    if (!mounted && id?.length > 0) {
      fetchPartisan(id);
      setMounted(true);
    } else if (!mounted && _.isEmpty(partisans)) {
      fetchPartisan();
      setMounted(true);
    }
  }, [mounted, fetchPartisan, id, partisans]);

  const partisan = useMatch('create') ? {} : partisans[partisanId];

  const onSubmit = async (formValues) => {
    if (partisanId?.length > 0) {
      updatePartisan(formValues, partisanId);
    } else {
      updatePartisan(formValues);
    }
    return;
  };

  const handleAliasSearch = async (searchTerm) => {
    // debounce search
    await searchAlias(searchTerm);
  };

  const parsePublics = (publics) => {
    if (!publics) {
      return;
    }
    return {
      name: _.last(publics.split('/')).split(':')[0],
      level: publics.split('/').length,
    };
  };

  const prepPublics = () => {
    if (!partisan || !partisan) {
      return;
    }
    const publics = _.sortBy(
      Object.values(
        Object.keys(partisan.publics[0]).map((item, index) => ({
          name: `${partisan.publics[0][item].name} (${
            parsePublics(item).name
          })`,
          value: item,
          level: parsePublics(item).level,
        }))
      ),
      'level'
    );

    return publics;
  };

  const prepOffices = () => {
    if (
      !partisan ||
      !partisan.offices ||
      _.isEmpty(partisan.offices) ||
      _.isEmpty(partisan.offices[0])
    ) {
      return;
    }
    const offices = _.sortBy(
      Object.values(partisan.offices).map((item, index) => ({
        name: `${item.name} (${parsePublics(item.divisionId).name})`,
        value: item.name,
        level: parsePublics(item.divisionId).level,
      })),
      'level'
    );

    // add object to beginning of array publics
    offices.unshift({
      name: 'Not interested in public office',
      value: 'None',
      level: 0,
    });

    return offices;
  };

  const renderAdditionalFields = () => {
    if (!partisan || !partisan.publics || _.isEmpty(partisan.publics)) {
      return;
    }

    const publics = prepPublics();
    const offices = prepOffices();

    if (!publics || !offices) {
      return;
    }

    return (
      <>
        <Field
          name="public"
          component={renderDropdown}
          label="Level of government most interested in:"
          options={publics}
        />
        <Field
          name="office"
          component={renderDropdown}
          label="Public office you would be most interested in:"
          options={offices}
        />
        <ConditionalField when="office" isnot="None">
          <Field
            name="electoralStatus"
            component={renderDropdown}
            label="What's your status?"
            options={electoralStatusOptions}
            defaultValue={
              _.find(electoralStatusOptions, 'selected')
                ? _.find(electoralStatusOptions, 'selected').value
                : '1'
            }
          />
        </ConditionalField>
      </>
    );
  };

  const renderPartisanForm = () => {
    const initialValues = {
      name: partisan?.name,
      bio: partisan?.bio,
      alias: partisan?.alias,
      address: partisan?.address,
      public: partisan?.public || 'ocd-division/country:us',
      office: partisan?.office || 'None',
      electoralStatus: partisan?.electoralStatus || '1',
    };

    return (
      <>
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={(formValues) => {
            const errors = {};

            if (!formValues.name) {
              errors.name = 'A name is required';
            }

            if (!formValues.bio) {
              errors.bio = 'Brief bio is required';
            }

            if (!formValues.address) {
              errors.address = 'An address (or ZIP code) is required';
            }

            if (formValues.alias) {
              const regex = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g;
              if (formValues.alias.match(regex)) {
                errors.alias =
                  'Your alias cannot contain spaces or special characters.';
              }
              if (
                search?.alias?.alias === formValues.alias &&
                search?.alias?.aliases?.length > 0
              ) {
                errors.alias = 'This alias is already taken.';
              }
            }

            if (!formValues.alias) {
              errors.alias = 'An alias is required';
            }

            return errors;
          }}
          render={({ handleSubmit, values, submitting, pristine }) => (
            <form
              onSubmit={handleSubmit}
              className="flex flex-col gap-4 w-full"
            >
              <Field
                name="name"
                component={renderInput}
                label="What is your name?"
              />
              <Field
                name="bio"
                component={renderTextarea}
                label="Describe yourself in a short paragraph."
                className="w-full"
              />
              <Field
                name="address"
                component={renderInput}
                label="Enter your address or ZIP code to identify your political jurisdictions and potential offices. USA ONLY."
              />
              <Field
                name="alias"
                component={LiveCheckInput}
                type="text"
                placeholder="alias"
                prefixPlaceholder="speeqr.org/"
                label="Your unique link"
                searchAction={handleAliasSearch}
                parse={(value) => value.toLowerCase()}
              />
              {renderAdditionalFields()}
              <button className="btn btn-primary" disabled={submitting}>
                {submitting ? 'Submitting' : 'Save'}
              </button>
            </form>
          )}
        />
      </>
    );
  };

  return (
    <div className="grid grid-flow-col p-5  justify-items-center items-center w-full">
      <div className="md:w-7/12">
        <div className="flex flex-row justify-between items-center mb-4">
          <h2 className="font-bold mb-4">
            {useMatch('create') && 'Create a New '}Partisan
          </h2>
          <Link
            to={`/partisan/${partisanId}`}
            className="btn btn-ghost btn-square"
          >
            <XMarkIcon className="w-6 h-6" />
          </Link>
        </div>
        {renderPartisanForm()}
      </div>
      <RecordEvent
        hitType="pageview"
        page={window.location.pathname}
        title="Edit Partisan"
      />
    </div>
  );
};

function mapStateToProps(state) {
  const electoralStatusOptions = [
    { name: 'just curious for now', value: 1 },
    { name: 'considering a run', value: 2 },
    { name: 'registered candidate', value: 3 },
    { name: 'elect (not yet in office)', value: 4 },
    { name: 'incumbent', value: 5 },
  ];

  return {
    partisans: state.partisan,
    currentUserId: state.auth.userId,
    partisanId: state.auth.partisan,
    electoralStatusOptions,
    auth: state.auth,
    search: state.search,
  };
}

export default connect(mapStateToProps, {
  fetchPartisan,
  updatePartisan,
  searchAlias,
})(EditPartisan);
