import _ from "lodash";
import React from "react";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import renderInput from '../../forms/CustomInput';
import renderRichTextarea from "../../forms/CustomRichTextarea";
import renderDropdown from "../../forms/CustomDropdown";
import Addresspicker from "../../forms/AddressPicker";
import { fetchProposal, updateProposal, deleteProposal } from '../../../actions';

import { XMarkIcon } from "@heroicons/react/24/solid";

const Condition = ({ when, is, children }) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) => (value === is ? children : null)}
    </Field>
);

const EditProposal = ({fetchProposal, updateProposal, deleteProposal, partisan, proposals}) => {

    const [mounted, setMounted] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        if (!mounted && id?.length>0) {
            fetchProposal(id);
            setMounted(true);
        }
    }, [mounted, fetchProposal, id]);

    const proposal = proposals[id];

    const onSubmit = (formValues) => {
        if (id?.length>0) {
            updateProposal(formValues, id);
        } else {
            updateProposal(formValues);
        }
        return;
    }

    const parsePublics = (publics) => {
        return ({
                name: _.last(publics.split('/')).split(':')[0],
                level: publics.split('/').length
            });
    }

    const prepPublics = () => {
        let publics;
        // get publics options from proposal, fallback from partisan
        if (proposal && !_.isEmpty(proposal.publics)) {
            publics = proposal.publics[0];
        } else {
            publics = partisan.publics[0];
        }
        return _.sortBy(Object.values(Object.keys(publics).map((item,index)=>
            ({
               name: `${publics[item].name} (${parsePublics(item).name})`,
               value: item,
               level: parsePublics(item).level
            })
        )),'level')
    }

    const renderLocationField = (form) => {
        return (
            <div className="flex flex-col sm:flex-row justify-stretch gap-4">
                <div className="flex flex-col sm:w-3/12 gap-3">
                    <div className="font-bold">
                        <label>Is the proposal related to a place?</label>
                    </div>
                    <div className="flex flex-row gap-4">
                        <div>
                            <Field
                                name="placeToggle"
                                component="input"
                                type="radio"
                                value="yes"
                                id="placeToggleYes"
                                />{' '}
                            <label htmlFor="placeToggleYes">
                                Yes
                            </label>
                        </div>
                        <div>
                            <Field
                                name="placeToggle"
                                component="input"
                                type="radio"
                                value="no" 
                                id="placeToggleNo"
                                onClick={() => form.mutators.clearInput('address')}
                            />{' '}
                            <label htmlFor="placeToggleNo">
                                No
                            </label>
                        </div>
                    </div>
                </div>
                <div className="sm:w-9/12">
                    <Condition when="placeToggle" is="yes" >
                        <Field 
                            name="address"
                            component={Addresspicker}
                            label="What is the address?"
                            placeholder="e.g. 123 Main Street, Anytown, USA"
                        />
                    </Condition>
                </div>
            </div>
        )            
    }

    const renderPublicField = () => {

        if (!partisan || !partisan.publics || _.isEmpty(partisan.publics)) {
            return;
        }
        
        const publics = prepPublics(); 

        if (!publics) {
            return;
        }

        return(
            <>
                <Field 
                    name="public"
                    component={renderDropdown}
                    label="Which level of government can best address this?"
                    options={publics}
                    defaultValue={_.find(publics,'selected')||publics[0].value}
                />
            </>
        )
    }

    const onDelete = () => {
        if (window.confirm('Are you sure you want to delete this proposal?')) {
            deleteProposal(id);
        }
    }

    const renderProposalForm = () => {

        const initValues = proposal ? {
            ...proposal,
            placeToggle: proposal.address ? 'yes' : 'no'
        } : {
            placeToggle: 'no'
        }

        return (
            <>
            <Form
                initialValues={initValues} 
                onSubmit={onSubmit}
                validate={formValues => {
                    const errors = {};

                    if (!formValues.title) {
                        errors.name = 'Describe your proposal in a sentence';
                    }

                    if (!formValues.description) {
                        errors.description = 'Describe your proposal in a paragraph';
                    }

                    if (!formValues.public) {
                        errors.public = 'Select a public';
                    }

                    if (formValues.placeToggle === 'yes' && (!formValues.address || !formValues.address.length || !formValues.address === '"null"')) {
                        errors.address = 'Enter an address';
                    }

                    return errors;
                }}
                mutators={{
                    clearInput: ([name], state, { changeValue }) => {
                        changeValue(state, name, () => '')
                    },
                }}
                render={({ handleSubmit, values, form, submitting, pristine }) => (
                    <form onSubmit={handleSubmit} className="ui form error">
                        <div className="flex flex-col my-4 gap-4">
                            <div className="flex flex-col gap-4">
                                <Field
                                    name="title"
                                    component={renderInput}
                                    label="What do you propose? Describe in a short sentence."
                                    autoFocus
                                />
                                <Field 
                                    name="description"
                                    component={renderRichTextarea}
                                    label="Explain the why of this proposal. Where does it need to take place? Who will it benefit? When does it need to happen?"
                                />
                                {renderPublicField()}
                            </div>
                            {values.public && values.public.length && (
                                    values.public.indexOf('place') > -1 || values.public.indexOf('county') > -1) &&
                                        renderLocationField(form)
                                }
                            <div className="flex flex-col">
                                <button 
                                    type="submit"
                                    disabled={submitting || pristine}
                                    className='btn btn-primary'>Save</button>
                            </div>
                        </div>
                    </form>
                )}
            />
            </>
        )
    }
    
    return (
        <div className="flex flex-col w-full justify-center items-center">
            <div className="flex flex-col p-4 sm:w-8/12 ">
                <div className="flex flex-row justify-between items-center">
                    <h2 className="text-2xl font-bold">{!id && 'Create a New '}Proposal</h2>

                    
                    <div className="flex flex-row gap-4">
                        {id && <button className="btn btn-error btn-outline" onClick={onDelete}>
                            delete
                        </button>}
                        <Link to="/" className="btn btn-square btn-ghost">
                            <XMarkIcon className="w-8 h-8" />
                        </Link>
                    </div>
                    
                </div>
                {renderProposalForm()}
            </div>
        </div>
    );
}

function mapStateToProps(state, ownProps) {

    var partisanId;
    if (state.auth && state.auth.partisan) {
        partisanId = state.auth.partisan;
    }

    return {
        proposals: state.proposals,
        currentUserId: state.auth.userId,
        partisan: state.partisan[partisanId]
    }
}

export default connect(mapStateToProps, {
    fetchProposal,
    updateProposal,
    deleteProposal
})(EditProposal);