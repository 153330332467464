import React from 'react';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { HomeIcon, PlusIcon, UserIcon } from '@heroicons/react/24/solid';

const BottomNav = ({ auth }) => {
  const CreatePostBtn = () => {
    return (
      <div className="dropdown dropdown-top mb-2 flex-none">
        <label
          tabIndex={0}
          className="m-0 self-center cursor-pointer btn btn-primary w-30"
        >
          <PlusIcon width={7} height={7} className="w-8 h-8 mr-2" /> Create
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content z-[1] menu p-2 shadow bg-base-200 rounded-box w-30 gap-2 w-32 items-center"
        >
          <li>
            <Link to="/create/post">Post</Link>
          </li>
          <li>
            <Link to="/create/proposal">Proposal</Link>
          </li>
        </ul>
      </div>
    );
  };

  const ProfileLink = auth && auth.partisan && `/partisan/${auth.partisan}`;

  return (
    <div className="btm-nav sm:invisible z-30 border-t bg-slate-100 pb-5 pt-1">
      <Link to={'/'}>
        <HomeIcon className="w-8 h-8" />
      </Link>
      {CreatePostBtn()}
      <Link to={ProfileLink}>
        <UserIcon className="w-8 h-8" />
      </Link>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    loader: state.loader,
  };
};

export default connect(mapStateToProps, null)(BottomNav);
