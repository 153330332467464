import React from "react";

class Map extends React.Component{

    render(){
        return(
            <div className="ui stackable grid">
                <div className="row centered">
                    <div className="ten wide column">
                        <hr/>
                        <p></p>
                    </div>
                </div>
            </div>        )
    }
}

export default Map;