import React from "react";
import Galea from "../../../utils/Galea";
import RecordEvent from "../../analytics/pageview";

const HowThisWorks = () => {

    document.title = "How this works - Speeqr";
    
    return (
        <>
        <Galea 
            title="How this works - Speeqr"
            description="How this works on Speeqr.org"
            url={`${process.env.REACT_APP_DOMAIN}/how-this-works`}
        />
        <RecordEvent 
            hitType='pageview' 
            page={window.location.pathname} 
            title='How This Works'
        />
        <div className="flex flex-row items-center justify-center mt-5 mb-10 px-5">
            <div className="page">
                <h1>How this works</h1>
                <p>
                    Speeqr is a platform for mobilizing democratic action and better governance.
                </p>
                <p>
                    The proposals on Speeqr are not only original made by citizens that would like to see change, but also documentation of the proposals made by elected officials and candidates for office.
                </p>
                <h2>I am a citizen</h2>
                <h3>... and I have a proposal to make</h3>
                <p>
                    Great! To get started, login, create a profile for yourself, and then create a proposal. You will be prompted to select the level of government that best can address your proposal.
                </p>
                <h3>... and I want to support a proposal</h3>
                <p>
                    To support a proposal, simply tap "Support" on the proposal. You will be prompted to enter your address so that we can verify that you are a constituent of the elected official that the proposal is addressed to. If you are not a constituent, you will be prompted to enter your email address so that we can notify you when the proposal is addressed.
                </p>
                <h2>
                    I am an elected official or candidate for office
                </h2>
                <h3>... and I want to create a campaign page</h3>
                <p>
                    Welcome! To get started either claim your profile or create a new one. Once you have a profile, you can create proposals and organize them into sets of priorities.
                </p>
                <h3>... why am I on here already and how do I update information about me?</h3>
                <p>
                    Citzens may create profiles for all elected officials and candidates for office in the United States. Our aim is to document the priorities and proposals that politicans make in order to make the connection between priorities and proposals to deepen the quality of the proposals through discussion and support. 
                </p>
                <p>
                    If you would like to claim your profile, please do so by emailing hello@speeqr.org with your name, email address, and the name of your elected office. Once you have claimed your profile, you can login and update your profile and create and edit proposals and priorities.
                </p>
            </div>
        </div>
        </>
    );

}

export default HowThisWorks;
