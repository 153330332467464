import axios from 'axios';
import history from '../history';
import {
    AUTH_USER,
    LOG_OUT,
    SET_LOADER,
    FETCH_PROPOSALS,
    FETCH_PROPOSAL,
    FETCH_PARTISAN_PROPOSALS,
    FETCH_PARTISAN_PLATFORMS,
    UPDATE_PROPOSAL,
    DELETE_PROPOSAL,
    FETCH_POSTS,
    FETCH_POST,
    UPDATE_POST,
    DELETE_POST,
    FETCH_PARTISAN,
    GET_PARTISAN,
    UPDATE_PARTISAN,
    POST_MESSAGE,
    POST_VOTE,
    DELETE_MESSAGE,
    ADD_NOTICE,
    DELETE_NOTICE,
    HIDE_NOTICE,
    ADD_MODAL,
    DELETE_MODAL,
    HIDE_MODAL,
    FETCH_AUTH_PARTISANS,
    ADD_AUTH_PARTISAN,
    SET_USER_PARTISAN,
    GET_PLATFORM,
    DELETE_PARTISAN_PLATFORM,
    UPDATE_PARTISAN_PLATFORM,
    UPDATE_PARTISAN_PLATFORM_PROPOSALS,
    SEARCH_ALIAS,
    FETCH_FEED,
    UPDATE_ITEM,
    DELETE_ITEM,
    CLEAR_FEED
} from './types';

export const setLoader = (type,bool) => async dispatch => {

    dispatch({
        type: SET_LOADER,
        payload: {[type]: bool}
    });
}

export const fetchUser = () => async dispatch => {

    const res = await axios.get('/api/current_user'); 
    if (!res.data || !res.data.email) {
        dispatch({
            type: AUTH_USER,
            payload: { permission: 'guest'}
        });
        return;
    }
    dispatch({
        type: AUTH_USER,
        payload: res.data
    });
    if (res.data._partisan && res.data._identity) {
        const res = await axios.get('/api/partisan'); 
        dispatch({
            type: FETCH_PARTISAN,
            payload: res.data
        });    
    } else {
        history.push('/edit/partisan');
        dispatch(addNotice({notice: "Welcome! Please complete your profile.", type: "positive", time: new Date()}));
    }
};

export const loginEmail = (formValues) => async dispatch => {

    const res = await axios.post('/auth/email', formValues);
    
    if (res && res.data && res.data.message) {
        dispatch(hideModal());
        history.push('/login/email_sent');
    } else {
        dispatch(addNotice({notice: "Error logging in", type: "negative", time: new Date()}));
    }

};

export const logOut = () => async dispatch => {

    const res = await axios.get('/api/logout'); 

    dispatch({
        type: LOG_OUT,
        payload: res
    });
};

export const postMessage = (formValues, id) => async dispatch => {

    const res = await axios.post(`/api/message/proposal/${id}`,{formValues}); 

    dispatch(addNotice({notice: "Comment posted!", type: "positive", time: new Date()}));

    dispatch({
        type: POST_MESSAGE,
        payload: res.data
    });
};

export const deleteMessage = (proposalId,messageId) => async dispatch => {

    const res = await axios.post(`/api/delete/message/${proposalId}`,{messageId}); 

    dispatch(addNotice({notice: "Comment deleted.", type: "positive", time: new Date()}));

    dispatch({
        type: DELETE_MESSAGE,
        payload: res.data
    });
};

export const postVote = (vote, type, id) => async dispatch => {
    const res = await axios.post(`/api/vote/${type}/${id}`,{vote})
        .catch(
            (err) => {
                console.log("postVote err",err);
                dispatch(addNotice({notice: err.response.data.error, type: "negative", time: new Date()}));
                return;
            }
        )
    if (!res) return;
    
    dispatch(addNotice({notice: "Vote recorded!", type: "positive", time: new Date()}));

    dispatch({
        type: POST_VOTE,
        payload: res.data
    });
};

export const postGuestVote = (formValues, id) => async dispatch => {

    try {
        const res = await axios.post(`/api/guest/proposal/vote/${id}`,{formValues});
        dispatch(addNotice({notice: "Please confirm your email address to record your vote.", type: "positive", time: new Date()}));

        dispatch({
            type: POST_VOTE,
            payload: res.data
        });
    } catch (err) {
        console.log("postGuestVote err",err);
        dispatch(addNotice({notice: err.response.data.error, type: "negative", time: new Date()}));
        return;
    }

};

export const postVoteConfirmation = (id, token) => async dispatch => {

    try {
        const res = await axios.get('/api/guest/proposal/email/verify',{params: {token}});
        dispatch(addNotice({notice: "Your vote has been recorded!", type: "positive", time: new Date()}));

        dispatch({
            type: POST_VOTE,
            payload: res.data
        });

        // show modal vote confirmation
        dispatch(showModal('vote-confirmation'));

    } catch (err) {
        history.push(`/proposal/${id}`);
        dispatch(addNotice({notice: err.response.data.error, type: "negative", time: new Date()}));
        return;
    }

};


export const fetchProposalsByAddress = (address) => async dispatch => {

    dispatch(setLoader('proposals',true));
    dispatch(setLoader('pusher',false));

    try {
        const res = await axios.get('/api/proposals/',{params: {address}}); 

        // sort by vote count
        res.data.sort((a,b) => {
            let aVotes = a.approve ? a.approve : 0;
            let bVotes = b.approve ? b.approve : 0;
            if (aVotes < bVotes) return 1;
            if (aVotes > bVotes) return -1;
            return 0;
        });

        dispatch({
            type: FETCH_PROPOSALS,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(addNotice({notice: err.response.data.error, type: "negative", time: new Date()}));
        dispatch(setLoader('proposals',false));
        return;
    }
    dispatch(setLoader('proposals',false));
};

export const fetchProposals = (ocd) => async dispatch => {

    dispatch(setLoader('proposals',true));

    const res = await axios.get('/api/proposal',{params: {ocd}}); 

    // sort by vote count
    res.data.sort((a,b) => {
        let aVotes = a.approve ? a.approve : 0;
        let bVotes = b.approve ? b.approve : 0;
        if (aVotes < bVotes) return 1;
        if (aVotes > bVotes) return -1;
        return 0;
    });

    dispatch({
        type: FETCH_PROPOSALS,
        payload: res.data
    });

    dispatch(setLoader('proposals',false));

};

export const fetchProposal = (id) => async dispatch => {

    try {
        const res = await axios.get(`/api/proposal/${id}`); 

        dispatch({
            type: FETCH_PROPOSAL,
            payload: res.data
        });

    } catch (err) {
        // history.push('/');
        dispatch(addNotice({notice: err.response.data.error, type: "negative", time: new Date()}));
        return;
    }
};

export const updateProposal = (formValues, id) => async dispatch => {

    var res;

    if (id && id.length) { // update proposal
        res = await axios.post(`/api/proposal/${id}`,{formValues});
        dispatch(addNotice({notice: "Proposal updated.", type: "positive", time: new Date()}));
    } else { // create proposal
        res = await axios.post('/api/proposal/',{formValues});
        dispatch(addNotice({notice: "Proposal posted. Nice work!", type: "positive", time: new Date()}));
    }
    
    dispatch({
        type: UPDATE_PROPOSAL,
        payload: res.data
    })

    dispatch({ type: UPDATE_ITEM, payload: {type: 'proposal', ...res.data} });
    
    history.push("/");

}

export const deleteProposal = (id) => async dispatch => {

    try {
        const res = await axios.delete(`/api/proposal/${id}`);

        dispatch(addNotice({notice: "Proposal deleted.", type: "positive", time: new Date()}));

        dispatch({
            type: DELETE_PROPOSAL,
            payload: res.data
        })

        dispatch({ type: DELETE_ITEM, payload: id });

        history.push("/");

    } catch (err) {
        dispatch(addNotice({notice: err.response.data.error, type: "negative", time: new Date()}));
        return;
    }
}

// partisan associated with the user
export const fetchPartisan = (id) => async dispatch => {

    // set fetch auth loader to true
    dispatch(setLoader('fetchPartisan',true));

    if (!id) return;

    const res = await axios.get('/api/partisan/',{id}); 

    if (res.data.length===0 || !res.data[0]._id) {
        history.push('/edit/partisan');
        dispatch(setLoader('fetchPartisan',false));
        return dispatch(addNotice({notice: "Welcome! Please complete your profile", type: "positive", time: new Date()}));
    }

    dispatch({
        type: FETCH_PARTISAN,
        payload: res.data[0]
    });
    dispatch(setLoader('fetchPartisan',false));
};

// partisan associated with the page (not necessarily the user)
export const getPartisan = (id) => async dispatch => {

    const res = await axios.get('/api/partisan/',{id});

    dispatch({
        type: GET_PARTISAN,
        payload: res.data
    });
};

export const updatePartisan = (formValues, partisanId) => async (dispatch,getState) => {

    const res = await axios.post('/api/partisan/',{formValues, partisanId});

    let redirect = false;
    
    // is partisan data set on state?
    const statePartisan = getState().partisan && getState().partisan[partisanId];

    redirect = statePartisan?.address && res.data.address===statePartisan.address;

    await dispatch({
        type: UPDATE_PARTISAN,
        payload: res.data
    })

    if (!redirect || !res.data.public) {
        dispatch(addNotice({notice: "Profile updated. Please update interest selections.", type: "positive", time: new Date()}));

        const res = await axios.get('/api/current_user'); 
        dispatch({
            type: AUTH_USER,
            payload: res.data
        });

        history.push("/edit/partisan");
    } else {
        dispatch(addNotice({notice: "Profile saved", type: "positive", time: new Date()}));
        history.push("/");
    }
}

export const fetchPartisanProposals = (alias) => async dispatch => {

    if (!alias) return;

    const res = await axios.get(`/api/partisan/${alias}/proposals`); 

    dispatch({
        type: FETCH_PARTISAN_PROPOSALS,
        payload: res.data
    });
}

export const getAuthPartisans = () => async dispatch => {
    const response = await axios.get('/api/auth_partisans');
    dispatch({ type: FETCH_AUTH_PARTISANS, payload: response.data });
}

export const postNewPartisan = (formValues) => async dispatch => {
    try {
        const response = await axios.post('/api/partisan', {formValues});
        dispatch({ type: FETCH_PARTISAN, payload: response.data });
        dispatch({ type: SET_USER_PARTISAN, payload: response.data });
        dispatch({ type: ADD_AUTH_PARTISAN, payload: response.data });
    } catch (err) {
        dispatch(addNotice({notice: err.response.data.error, type: "negative", time: new Date()}));
        return;
    }
    getAuthPartisans();
    dispatch(addNotice({notice: "Profile generated", type: "success", time: new Date()}));
    history.push('/edit/partisan');
}

export const switchPartisan = (id) => async dispatch => {
    dispatch(setLoader('page',true));
    let link = '';
    try {
        const response = await axios.get(`/api/partisans/switch/${id}`);
        dispatch({ type: FETCH_PARTISAN, payload: response.data });
        dispatch({ type: SET_USER_PARTISAN, payload: response.data });
        dispatch(fetchProposalsByAddress());
        dispatch(addNotice({notice: "Profile switched", type: "positive", time: new Date()}));
        link = response.data.alias ? `/${response.data.alias}` : `/edit/partisan`;
    } catch (err) {
        dispatch(addNotice({notice: err.data.message, type: "negative", time: new Date()}));
        return;
    }
    fetchUser();
    dispatch(setLoader('page',false));
    // switch to partisan alias
    history.push(`${link}`);
}

export const getPartisanPlatforms = (alias) => async dispatch => {
    try {
        const response = await axios.get(`/api/platforms/partisan/${alias}`);
        dispatch({ type: FETCH_PARTISAN_PLATFORMS, payload: response.data });
    } catch (err) {
        dispatch(addNotice({notice: err.data.message, type: "negative", time: new Date()}));
        return;
    }
}

export const getPlatform = (platformId) => async dispatch => {
    try {
        const response = await axios.get(`/api/platforms/${platformId}`);
        dispatch({ type: GET_PLATFORM, payload: response.data });
    } catch (err) {
        dispatch(addNotice({notice: err.data.message, type: "negative", time: new Date()}));
        return;
    }
}

export const updatePlatform = (partisanId, formValues) => async dispatch => {
    try {
        const response = await axios.post(`/api/platforms/${formValues.id}`, {formValues});
        dispatch({ type: UPDATE_PARTISAN_PLATFORM, payload: {_id: partisanId, platform: response.data} });
        dispatch(addNotice({notice: "Platform updated", type: "positive", time: new Date()}));
    } catch (err) {
        dispatch(addNotice({notice: err.data.message, type: "negative", time: new Date()}));
        return;
    }
}

export const addProposalToPlatform = (partisanId, platformId, proposalId) => async dispatch => {
    try {
        const response = await axios.post(`/api/platforms/${platformId}/proposal/${proposalId}`);
        dispatch({ type: UPDATE_PARTISAN_PLATFORM_PROPOSALS, payload: {_id: partisanId, platform: response.data} });
        dispatch(addNotice({notice: "Proposal added to platform", type: "positive", time: new Date()}));
    } catch (err) {
        dispatch(addNotice({notice: err.data.message, type: "negative", time: new Date()}));
        return;
    }
}

export const removeProposalFromPlatform = (partisanId, platformId, proposalId) => async dispatch => {
    try {
        const response = await axios.delete(`/api/platforms/${platformId}/proposal/${proposalId}`);
        dispatch({ type: UPDATE_PARTISAN_PLATFORM_PROPOSALS, payload: {_id: partisanId, platform: response.data} });
        dispatch(addNotice({notice: "Proposal removed from platform", type: "positive", time: new Date()}));
    } catch (err) {
        dispatch(addNotice({notice: err.data.message, type: "negative", time: new Date()}));
        return;
    }
}

export const deletePlatform = (partisanId, platformId) => async dispatch => {
    try {
        await axios.delete(`/api/platforms/${platformId}`);
        dispatch({ type: DELETE_PARTISAN_PLATFORM, payload: {_id: partisanId, platformId} });
        dispatch(addNotice({notice: "Platform deleted", type: "positive", time: new Date()}));
    } catch (err) {
        dispatch(addNotice({notice: err.data.message, type: "negative", time: new Date()}));
        return;
    }
}

export const addNotice = (notice) => {
    return { 
        type: ADD_NOTICE,
        payload: notice
    }
}

export const hideNotice = () => {
    return {
        type: HIDE_NOTICE
    }
}

export const deleteNotice = () => {
    return {
        type: DELETE_NOTICE
    }
}

export const showModal = (modal) => {
    return { 
        type: ADD_MODAL,
        payload: modal
    }
}

export const hideModal = () => {
    // delete modal after 1 second
    setTimeout(() => {
        deleteModal();
    }, 1000);
    return {
        type: HIDE_MODAL
    }
}

export const deleteModal = () => {
    return {
        type: DELETE_MODAL
    }
}

export const searchAlias = (alias) => async dispatch => {
    try {
        const response = await axios.get(`/api/search/alias/${alias}`);
        dispatch({ type: SEARCH_ALIAS, payload: response.data });
    }
    catch (err) {
        dispatch(addNotice({notice: err.response.data.message, type: "error", time: new Date()}));
    }
}

export const fetchPosts = (ocd) => async dispatch => {
    try {
        const response = await axios.get('/api/posts', {params: {ocd}});
        dispatch({ type: FETCH_POSTS, payload: response.data });
    }
    catch (err) {
        dispatch(addNotice({notice: err.response.data.message, type: "error", time: new Date()}));
    }
}

export const fetchPost = (id) => async dispatch => {
    try {
        const response = await axios.get(`/api/posts/${id}`);
        dispatch({ type: FETCH_POST, payload: response.data });
    }
    catch (err) {
        dispatch(addNotice({notice: err.response.data.message, type: "error", time: new Date()}));
    }
}

export const updatePost = (formValues) => async dispatch => {
    try {
        const response = await axios.post(`/api/posts/`, {formValues});
        dispatch({ type: UPDATE_POST, payload: response.data });
        dispatch({ type: UPDATE_ITEM, payload: {type: 'post', ...response.data} });
        dispatch(addNotice({notice: "Post updated", type: "positive", time: new Date()}));
        history.push(`/posts/${response.data._id}`);
    }
    catch (err) {
        console.log("updatePost err",err);
        dispatch(addNotice({notice: err.response.data.error, type: "error", time: new Date()}));
    }
}

export const deletePost = (id) => async dispatch => {
    try {
        await axios.delete(`/api/posts/${id}`);
        dispatch({ type: DELETE_POST, payload: id });
        dispatch({ type: DELETE_ITEM, payload: id });
        dispatch(addNotice({notice: "Post deleted", type: "positive", time: new Date()}));
    }
    catch (err) {
        dispatch(addNotice({notice: err.response.data.message, type: "error", time: new Date()}));
    }
}

export const fetchPostsByAddress = (address) => async dispatch => {

    dispatch(setLoader('posts',true));
    dispatch(setLoader('pusher',false));

    try {
        const res = await axios.get('/api/posts/',{params: {address}}); 

        dispatch({
            type: FETCH_POSTS,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(addNotice({notice: err.response.data.error, type: "negative", time: new Date()}));
        dispatch(setLoader('posts',false));
        return;
    }
    dispatch(setLoader('posts',false));
};

export const fetchFeed = ({address, ocd, fromPostDate, fromProposalDate, limit}) => async dispatch => {
    
    dispatch(setLoader('feed',true));
    dispatch(setLoader('pusher',false));

    try {
        const res = await axios.get('/api/feed/',{params: {address, ocd, fromPostDate, fromProposalDate, limit}});

        // clear feed if no fromPostDate or fromProposalDate
        if (!fromPostDate && !fromProposalDate) {
            dispatch(clearFeed());
        }
        
        dispatch({
            type: FETCH_FEED,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(addNotice({notice: err.response.data.error, type: "negative", time: new Date()}));
        dispatch(setLoader('feed',false));
        return;
    }
    dispatch(setLoader('feed',false));

}

export const clearFeed = () => async dispatch => {
    dispatch({
        type: CLEAR_FEED
    });
}
