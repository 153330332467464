import React from "react";
import Galea from "../../../utils/Galea";
import RecordEvent from "../../analytics/pageview";

const HowThisWorks = () => {

    return (
        <>
        <Galea 
            title="Claim your profile - Speeqr"
            description="Claim your profile on Speeqr.org"
            url={`${process.env.REACT_APP_DOMAIN}/claim-profile`}
        />
        <RecordEvent 
            hitType='pageview' 
            page={window.location.pathname} 
            title='Claim Profile'
        />
        <div className="flex flex-row items-center justify-center mt-5 mb-10 px-5">
            <div className="page">
                <h1>Claim your profile</h1>
                <p>
                    Citzens may create profiles for all elected officials and candidates for office in the United States. Our aim is to document the priorities and proposals that politicans make in order to make the connection between priorities and proposals to deepen the quality of the proposals through discussion and support. 
                </p>
                <p>
                    If you would like to claim your profile, please do so by emailing hello@speeqr.org with your name, email address, and the name of your elected office. Once you have claimed your profile, you can login and update your profile and create and edit proposals and priorities.
                </p>
            </div>
        </div>
        </>
    );

}

export default HowThisWorks;
