import _ from "lodash";
import React, {useCallback, useEffect, useState } from "react";

import { Form, Field } from 'react-final-form';
import { Link, useParams } from "react-router-dom";
import { connect } from 'react-redux';

import { updatePost, fetchPost } from "../../../actions";

import renderRichTextarea from "../../forms/CustomRichTextarea";
import renderDropdown from "../../forms/CustomDropdown";

import { XMarkIcon } from "@heroicons/react/24/outline";

const EditPost = (props) => {

    const [mounted, setMounted] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        if (!mounted && id?.length>0) {
            props.fetchPost(id);
            setMounted(true);
        }
    }, [mounted, props.fetchPost, id, props]);

    const focusPost = useCallback((inputElement) => {
        if (inputElement) {
          inputElement.focus();
        }
      }, []);

    const post = props?.posts[id] || {
        post: '',
        publics: [],
        _id: ''
    };

    const onSubmit = (formValues) => {
        console.log(formValues);
        props.updatePost(formValues);
        return;
    }

    const parsePublics = (publics) => {
        return ({
                name: _.last(publics.split('/')).split(':')[0],
                level: publics.split('/').length
            });
    }

    const prepPublics = () => {
        let publics;
        // get publics options from proposal, fallback from partisan
        // if (post && !_.isEmpty(post.publics)) {
        //     publics = post.publics[0];
        // } else {
            publics = props.partisan.publics[0];
        // }
        return _.sortBy(Object.values(Object.keys(publics).map((item,index)=>
            ({
               name: `${publics[item].name} (${parsePublics(item).name})`,
               value: item,
               level: parsePublics(item).level
            })
        )),'level')
    }

    const renderPublicField = () => {

        if (!props.partisan || !props.partisan.publics || _.isEmpty(props.partisan.publics)) {
            return;
        }
        
        const publics = prepPublics(); 

        if (!publics) {
            return;
        }

        return(
            <>
                <Field 
                    name="public"
                    component={renderDropdown}
                    label="Which level of government is this about?"
                    options={publics}
                    defaultValue={_.find(publics,'selected')||publics[0].value}
                />
            </>
        )
    }

    const renderform = () => {
        return (
            <Form
                onSubmit={onSubmit}
                initialValues={post}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit} className="form error">
                        <div className="flex flex-col my-4 gap-4 min-h-[13em]">
                            <Field
                                name="post"
                                component={renderRichTextarea}
                                placeholder="What do you want to say?"
                                customClass="w-full h-[10em]"
                                autoFocus
                                editorRef={focusPost}
                            />
                        </div>
                        <div className="flex flex-col my-4 gap-4">
                            {renderPublicField()}
                        </div>
                        <div className="flex justify-between gap-4 w-full">
                            <button
                                className="btn btn-primary"
                                type="submit"
                                disabled={submitting || pristine}
                            >
                                Post
                            </button>
                        </div>
                    </form>
                )}
            />
        )
    }


    return (
        <>
            <div className="flex flex-col w-full items-center">
                <div className="flex flex-col p-4 w-full sm:w-8/12">
                    <div className="flex flex-row justify-between items-center">
                        <h2 className="text-2xl font-bold">Create a Post</h2>
                        <div className="flex flex-row gap-4">
                            <Link to="/" className="btn btn-square btn-ghost">
                                <XMarkIcon className="w-8 h-8" />
                            </Link>
                        </div>
                    </div>
                    {renderform()}
                </div>
            </div>
        </>
    );

}

const mapStateToProps = (state, ownProps) => {

    var partisanId = state?.auth?.partisan;

    return {
        partisan: state.partisan[partisanId],
        posts: state.posts
    }
}

export default connect(mapStateToProps, { updatePost, fetchPost })(EditPost);