import React from "react";
import { Field, Form } from "react-final-form";
import renderInput from "../../forms/CustomInput";

import { connect } from "react-redux";
import { loginEmail } from "../../../actions";

const Login = (props) => {

    const onSubmit = (formValues) => {
        props.loginEmail(formValues);
    }
       
    const renderMagicLinkForm = () => {
        return (
            <Form
                onSubmit={onSubmit}
                render={({ handleSubmit, submitting, pristine }) => (
                    <form onSubmit={handleSubmit} className="ui form error">
                                <Field
                                    name="email"
                                    component={renderInput}
                                    placeholder="Enter your email address"
                                />
                                <button 
                                    className="btn btn-primary w-full mt-2" 
                                    type="submit"
                                    disabled={submitting || pristine}
                                >Sign-in with Email</button>
                    </form>
                )}
            />   
        )
    }

    return (
        <>
        <div className="flex flex-col md:flex-row justify-between gap-6">
            <div className="flex justify-center items-center">
                <div>
                    <div className="text-xl font-bold">Welcome!</div>
                    <p className="py-2">To get started or to sign-in use the "Sign-in with Google" link or enter your email address and tap "Sign-in with Email".</p>
                    <p className="py-2"><strong>New here?</strong> If you do not have an account, you will be prompted to complete a brief profile upon login.</p>
                </div>
            </div>
            <div className="flex flex-col w-full">
                <div className='py-2'>
                        <a className="btn btn-secondary w-full" href="/auth/google">
                        <i className='icon google'></i>
                        Sign-in with Google
                        </a>
                </div>
                <div className='divider'>Or</div>
                <div className="flex flex-col gap-4">
                    {renderMagicLinkForm()}
                </div>
            </div>
        </div>
        </>
    );

}

export default connect(null, { loginEmail })(Login);