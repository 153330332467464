import React from "react";
import { Helmet } from "react-helmet";

const Galea = ({ 
        title = "Speeqr.org",
        description = "A platform for mobilizing democratic action and better governance.",
        image = process.env.REACT_APP_DOMAIN + "/card.png",
        url = process.env.REACT_APP_DOMAIN
    }) => {

    const descInPlainText = description.replace(/(<([^>]+)>)/gi, "");
    const shortDescription = descInPlainText.substring(0, 160);

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={shortDescription} />
            <meta name="image" content={image} />
            <link rel="canonical" href={url} />
            <meta property="og:url" content={url} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={shortDescription} />
            <meta property="og:image" content={image} />
        </Helmet>
    )

}

export default Galea;