import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import './CustomRichTextarea.css';

function renderError({error, touched}) {
    if (touched && error) {
        return (
            <div className="ui error message">
                <div className="header">{error}</div>
            </div>
        );
    }
}

const renderRichTextarea = ({input, label, meta, customClass, placeholder, editorRef}) => {

    const className = `${meta.error && meta.touched ? 'error': ''} ${customClass}`
    return (
        <div className={className}>
            <label>{label}</label>
            <ReactQuill
                {...input} 
                ref={editorRef}
                theme="snow"
                onChange={(newValue, delta, source) => {
                    if (source === 'user') {
                      input.onChange(newValue);
                    }
                  }}
                onBlur={(range, source, quill) => {
                    input.onBlur(quill.getHTML());
                }}
                className="h-full"
                placeholder={placeholder}
                modules={
                    {
                        toolbar: [
                            ['bold', 'italic', 'underline'],
                            [{'list': 'ordered'}, {'list': 'bullet'}],
                            ['blockquote','link']
                        ],
                        clipboard: {
                            matchVisual: false
                        }
                    }
                }
            />
            {renderError(meta)}
        </div>
        );
}

export default renderRichTextarea;