import React from 'react';

import { Link } from 'react-router-dom';

import { UserCircleIcon } from '@heroicons/react/24/solid';

const PartisanLine = ({partisan, noLink, office, status}) => {

    if (!partisan) {
        return null;
    }

    const { _id, name, alias, profileImages } = partisan;
    const size = noLink ? 'w-7 h-7' : 'w-9 h-9';

    const renderProfileImage = () => {
        if (profileImages && profileImages.length > 0) {

            const profileImage = profileImages[profileImages.length - 1];

            return (
                <img
                    className="rounded-full"
                    src={profileImage.imageFile}
                    alt={name}
                />
            )

        } else {

            return <UserCircleIcon className={size} />

        }

    }

    if (noLink) {
        return (
            <div className="flex flex-row items-center text-base gap-1 flex-nowrap whitespace-nowrap">
                {renderProfileImage()}
                {name}
            </div>
        )
    }

    const link = alias ? `/${alias}` : `/partisan/${_id}`;

    const renderOffice = () => {
        if (office) {
            return (
                <div className="flex flex-col gap-0 item-start">
                    <span className="text-xs text-gray-500">{partisan.office}</span>
                </div>
            )
        }
    }
    
    return (
        <Link to={link}>
            <div className="flex flex-row items-center text-base gap-1 flex-nowrap whitespace-nowrap w-full">
                <div className="flex flex-col pt-0.5">
                    {renderProfileImage()}
                </div>
                <div className="flex flex-col">
                    {name}
                    {renderOffice()}
                    {status ? <span className="text-xs bg-purple-100 self-start px-0.5 py-0.5 rounded">
                        {parseInt(partisan.electoralStatus) === 3 ? 'Candidate' : parseInt(partisan.electoralStatus) >= 3 ? 'Incumbent' : null}
                        </span>: null}
                </div>
            </div>
        </Link>
    )

}

export default PartisanLine;