import _ from "lodash";
import React from "react";

import { Link } from "react-router-dom";

const Breadcrumb = (props) => {

    const renderSections = () => {
        const breadcrumb = props.ocd;
        const sections = breadcrumb.map((item,i,arr)=>{
            if (!_.isEmpty(item.name)) {
                return (
                    <React.Fragment key={item.name}>
                    <div className="divider"> / </div>
                    <Link 
                        to={item.uri} 
                        className={`section ${i + 1 === arr.length && 'active'}`}
                    >
                        {item.name}
                    </Link>
                    </React.Fragment>
                )
            }
            return <React.Fragment key="empty"></React.Fragment>;
        })
        return sections;
    }

    return (
        <div className="ui breadcrumb">
            <Link to={'/'} className="section">
                <i className='icon map marker alternate large'></i>
            </Link>
            {renderSections()}
        </div>
    )
}

export default Breadcrumb;