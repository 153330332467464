import { 
    FETCH_PARTISAN,
    UPDATE_PARTISAN,
    UPDATE_PARTISAN_PLATFORM,
    UPDATE_PARTISAN_PLATFORM_PROPOSALS,
    DELETE_PARTISAN_PLATFORM
} from '../actions/types';

const partisanReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_PARTISAN:
            return { ...state, [action.payload._id]: action.payload }
        case UPDATE_PARTISAN:
            return { ...state, [action.payload._id]: action.payload }
        case UPDATE_PARTISAN_PLATFORM:
            return { ...state, [action.payload._id]: {
                ...state[action.payload._id],
                platforms: [
                    ...state[action.payload._id].platforms,
                    action.payload.platform
                ]
            }
        }
        case UPDATE_PARTISAN_PLATFORM_PROPOSALS:
            // find and replace the platform with property _id equal to payload.platform._id
            const platforms = state[action.payload._id].platforms.map(platform => {
                if (platform._id === action.payload.platform._id) {
                    return action.payload.platform;
                }
                return platform;
            });

            return { ...state, [action.payload._id]: {
                ...state[action.payload._id],
                platforms: platforms
                    // ...state[action.payload._id].platforms,
                    // action.payload.platform

                    // replace the platform with property _id equal to payload.platform._id
                    // ..._.replace(state[action.payload._id].platforms, { _id: action.payload.platform._id })
                    // replace the platform with property _id equal to payload.platform._id
                
            }
        }
        case DELETE_PARTISAN_PLATFORM:

            // filter out the platform with property _id equal to payload.platformId
            const revisedPlatforms = state[action.payload._id].platforms.filter(platform => platform._id !== action.payload.platformId);

            return { ...state, [action.payload._id]: {
                ...state[action.payload._id],
                // omit the platform from the platforms array by id
                // platforms: _.omit(state[action.payload._id].platforms, action.payload.platformId)
                // omit where id on property of platforms is equal to platformId
                platforms: revisedPlatforms
            }
        }
        default:
            return state;
    }
};

export default partisanReducer;