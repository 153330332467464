import _ from 'lodash';
import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import Loader from '../../Loader';

import { ArrowDownIcon } from '@heroicons/react/24/solid';

const ListPlatform = ({platforms, isSignedIn, partisan, proposals}) => {

    const [showPlatform, setShowPlatform] = useState(0);

    // loading 
    if (!platforms) {
        return <Loader loader='platformsList' />;
    }

    if (!platforms || _.isEmpty(platforms) || !platforms.length) {
        return <>No priorities defined.</>;
    }

    // loading 
    if (!platforms || _.isEmpty(platforms) || !platforms.length) {
        return null;
    }

    // toggle platform accordion
    const togglePlatform = (platform) => {
        setShowPlatform(platform);
    }

    return platforms.map((platform) => {
        if (!platform.title) {
            return null;
        }
        // get proposals for this platform
        // const platformProposals = proposals && Object.values(proposals).filter(proposal => platform.proposals.includes(proposal._id) || platform.proposals.includes(proposal));
        const platformProposals = platform.proposals && platform.proposals.length > 0 ? platform.proposals : null;

        return (
            <div key={platform._id} className="flex flex-row w-full">
                <div className="my-2 border-gray-400 rounded flex flex-col shadow-sm items-center px-3 py-4 bg-purple-50 border w-full">
                    <div className="flex flex-row justify-between w-full cursor-pointer" onClick={() => togglePlatform(platform._id)}>
                        <h3 className='text-xl font-semibold'>{platform.title}</h3>
                        <div className="flex flex-row items-center">
                            <ArrowDownIcon 
                                className={`h-5 w-5 text-gray-500 cursor-pointer hover:text-gray-900 transition transform ${showPlatform !== platform._id && `transition transform rotate-90`}`} 
                            />
                        </div>
                    </div>
                    {showPlatform === platform._id && <div className="flex flex-col justify-between w-full">
                        <p className="text text-gray-500">{platform.description}</p>
                        {platformProposals?.length > 0 ? (
                            <>
                            <div className="divider uppercase text-sm">Proposals</div>
                            <div className="flex flex-col gap-2 pb-2 items-center">
                                {platformProposals.map(proposal => {
                                    return (
                                        <div key={proposal._id}
                                            className="flex flex-row text-center gap-1"
                                            >
                                            <Link to={`/proposal/${proposal._id}`} className="text-base font-semibold">{proposal.title}</Link>
                                        </div>
                                    );
                                }
                                )}
                            </div>
                            </>
                    ) : (<div className="text-sm text-accent">No proposals found for this priority.</div>)}
                    </div>}
                </div>
            </div>
        );
    });
}

export default ListPlatform;