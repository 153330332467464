import _ from "lodash";
import { 
    FETCH_FEED,
    FETCH_ITEM,
    UPDATE_ITEM,
    DELETE_ITEM,
    CLEAR_FEED
} from '../actions/types';

const feedReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_FEED:
            return {
                ...state,
                items: { 
                    ...state.items,
                    ..._.mapKeys(action.payload.items, '_id') 
                },
                count: action.payload.count,
                more: action.payload.more,
                fromPostDate: action.payload.fromPostDate,
                fromProposalDate: action.payload.fromProposalDate,
                partisans: action.payload.partisans
            }
        case FETCH_ITEM:
            return { 
                ...state, 
                items: { [action.payload._id]: action.payload, ...state.items }
            };
        case UPDATE_ITEM: 
            return {
                ...state,
                items: { [action.payload._id]: action.payload, ...state.items }
            };
        case DELETE_ITEM:
            return {
                ...state,
                items: _.omit(state.items, action.payload)
            }
        case CLEAR_FEED:
            return {
                ...state,
                items: {},
                count: 0,
                more: false,
                fromPostDate: null,
                fromProposalDate: null,
                partisans: []
            }
        default:
            return state;
    }
};

export default feedReducer;