import _ from "lodash";
import { 
    FETCH_PROPOSALS,
    FETCH_PROPOSAL, 
    UPDATE_PROPOSAL, 
    DELETE_PROPOSAL, 
    POST_MESSAGE,
    POST_VOTE,
    DELETE_MESSAGE
} from '../actions/types';

const proposalReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_PROPOSALS:
            return { ..._.mapKeys(action.payload, '_id') };
        case FETCH_PROPOSAL:
            return { ...state, [action.payload._id]: action.payload }
        case UPDATE_PROPOSAL:
            return { ...state, [action.payload._id]: action.payload }
        case DELETE_PROPOSAL:
            return _.omit(state, action.payload);
        case POST_MESSAGE:
            return { ...state, [action.payload._id]: action.payload }
        case POST_VOTE:
            return { ...state, [action.payload._id]: action.payload }
        case DELETE_MESSAGE:
            return { ...state, [action.payload._id]: action.payload }    
        default:
            return state;
    }
};

export default proposalReducer;