import React from "react";

import ReadMore from "../../ui/ReadMore";

import { connect } from 'react-redux';

import { Link } from "react-router-dom";

import PartisanLine from "../../partisan/line";

import formatDate from "../../../utils/formatDate";
import { fromOCD } from "../../../utils/fromOCD";

import { deletePost } from "../../../actions";

import { EllipsisHorizontalIcon  } from "@heroicons/react/24/outline";

const PostCard = (props) => {

    const { _id, post, _partisan, public: ocdLine, dateCreated } = props.post;

    const formattedDate = formatDate(dateCreated);
    const publicItem = ocdLine ? fromOCD(ocdLine) : {};
    const publicItemUri = ocdLine ? publicItem?.uri : '';

    if (!post) {
        return <div>Loading...</div>
    }

    const handleDelete = () => {
        // are you sure?
        window.confirm("Are you sure you want to delete this post?") && props.deletePost(_id);
    }

    const renderDropdown = () => {
        return (
            <div className="relative inline-block text-left">
               <div className="dropdown dropdown-end dropdown-hover cursor-pointer">
                    <div tabIndex={0} className="m-0">
                        <EllipsisHorizontalIcon className="w-5 h-5 text-gray-500" />
                    </div>
                    <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-slate-100 rounded-box w-48 z-20 ">
                        <li>
                            <Link to={`/edit/post/${_id}`}>Edit</Link>
                        </li>
                        <li>
                            <Link onClick={handleDelete}>Delete</Link>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }


    return (
        <>
            <div className="card bordered mb-4">
                <div className="card-body gap-2 px-5 py-4">
                    <div className="flex justify-between flex-row relative w-full">
                        <PartisanLine partisan={_partisan} />
                        {renderDropdown()}
                    </div>
                    <div className=" text-gray-700">
                        <ReadMore
                            maxCharacterCount={300}>
                            {post}
                        </ReadMore>
                    </div>
                    <div className="flex flex-row gap-4">
                        <div className="badge badge-primary">
                            <Link
                                to={`/publics${publicItemUri}`}
                                className="hover:text-white"
                            >
                                <b>{publicItem.name} {`${publicItem.type!=="place"?publicItem.type:''}`}</b>
                            </Link>
                        </div>
                        <Link to={`/${_partisan.alias}/posts/${_id}`} className="text-gray-500 hover:text-gray-900 text-sm">
                            {formattedDate}
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default connect(null, { deletePost })(PostCard);