import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';

const RecordEvent = ({
    hitType = 'pageview',
    page = window.location.pathname,
    title = document.title,
    isAdmin
}) => {
    
    // ony record pageview if in production or not an admin
    useEffect(() => {
        if (process.env.NODE_ENV !== 'production' || isAdmin) {
            return;
        }
        
        ReactGA.send({ 
            hitType, 
            page,
            title 
        });
    }, [hitType, page, title, isAdmin]);

    return <></>;
}

const mapStateToProps = (state) => {

    const isAdmin = state?.auth?.permission === 2 ? true : false;

    return {
        isAdmin
    };
};

export default connect(mapStateToProps)(RecordEvent);