import './index.css';
import _ from 'lodash';
import React from "react";
import { connect } from "react-redux";
import { 
    fetchProposal, 
    postMessage, 
    postVote, 
    deleteMessage,
    postGuestVote,
    postVoteConfirmation,
    showModal
} from "../../../actions";
import { useParams, Link, useLocation } from "react-router-dom";
import { Form, Field } from 'react-final-form';
import renderTextarea from '../../forms/CustomTextarea';
import Breadcrumb from '../../navigation/breadcrumb';
import BottomNav from '../../navigation/mobilenav';
import ProposalMap from '../ProposalMap';

import PartisanLine from '../../partisan/line';

import { fromOCD } from '../../../utils/fromOCD';

import history from '../../../history';
import Loader from '../../Loader';

import VoteModal from "../../vote/modal"; 
import Galea from '../../../utils/Galea';
import RecordEvent from "../../analytics/pageview";

import { ArrowLeftIcon, HandThumbUpIcon, HandThumbDownIcon, TrashIcon } from '@heroicons/react/24/solid';

import { StarIcon } from "@heroicons/react/24/outline";
import { StarIcon as StarIconSolid } from "@heroicons/react/24/solid";

const Item = (props) => {

    
    const { id, token } = useParams();
    
    const [mounted, setMounted] = React.useState(false);
    const location = useLocation();
    
    // is this proposal platformed by the current partisan
    const platformed = props.myPlatforms && props.myPlatforms.find(platform => platform.proposals.find(proposal => proposal === id || proposal?._id === id));

    React.useEffect(() => {
        if (!mounted) {
            props.fetchProposal(id);
            if (token?.length>0) {
                props.postVoteConfirmation(id, token);
            }
            setMounted(true);
        }
    }, [mounted, props, id, token]);

    if (!props.proposals || !props.proposals[id]) {
        return <Loader loader='proposalDetail' />
    }

    const { title, description, _partisan, votes, approve } = props.proposals[id];
    const proposal = props.proposals[id];

    const handleStar = (id) => {
        props.showModal(`platformProposal-${id}`);
    }

    const renderEditButton = () => {

        // if logged in user is not author of proposal, don't show edit button
        // change later to allow admins and moderators to edit
        if (props.auth && props.auth.partisan 
                && _partisan._id !== props.auth.partisan) {
            return;
        }

        if (props.auth && props.auth.userId) {
            return(
                <Link 
                    to={`/edit/proposal/${id}`}
                    className="btn btn-ghost"
                >
                    Edit
                </Link>
            )
        }
    }

    const onSubmit = async (formValues) => {
        await props.postMessage(formValues, id);
        return;
    }

    const deleteMessage = (messageId) => {
        // show confirmation dialog
        const confirmed = window.confirm("Are you sure you want to delete this message?");
        if (confirmed) {
            props.deleteMessage(id,messageId);
        }
        return;
    }

    const castVote = (vote,type) => {
        props.postVote(vote,type,id);
        return;
    }

    const renderMessageControl = (messageId, partisanId) => {
        if (partisanId===props.auth.partisan) {
            return (
                <button 
                    className="btn btn-square btn-ghost btn-sm" 
                    onClick={()=>deleteMessage(messageId)} 
                    style={{cursor:'pointer'}}
                >
                    <TrashIcon className="h-5 w-5" />
                </button>
            )
        } 
    }

    const renderComments = () => {
        return proposal.discussion.map(({_id, message, _partisan}) => {
            if (!_partisan || !_partisan.name) {
                return null;
            }
            return (
                <div className="flex flex-col mb-3" key={_id}>
                        <div className='mb-1 w-fit'>
                            <div className="bg-slate-200 px-2 py-1 rounded-lg mb-1">
                                {message}
                            </div>
                            <div className="flex items-center justify-between mb-2 w-full">
                            <PartisanLine partisan={_partisan} noLink />
                            {renderMessageControl(_id,_partisan._id)}
                            </div>
                        </div>
                </div>
                )
            }
        )
    }

    const renderForm = () => {
        if (!props.auth || !props.auth.userId) {
            return <div className='mb-4'>Login to comment</div>
        }
        if (!props.canVote) {
            return <div className='mb-4'>Please switch to your personal profile to comment.</div>
        }
        return(
            <Form
                initialValues={props.partisan} 
                onSubmit={onSubmit}
                validate={formValues => {
                    const errors = {};

                    if (!formValues.message || formValues.message.length<10) {
                        errors.message = 'A message is required';
                    }

                    return errors;
                }}

                render={({ handleSubmit, form, pristine, submitting }) => (
                    <form onSubmit={event => {
                        handleSubmit(event)
                        form.restart()
                      }} className="ui form error">
                        <div className="one column stackable ui grid">
                            <div className="column">
                                <Field
                                    name="message"
                                    component={renderTextarea}
                                    label="What do you think?"
                                />
                            </div>
                            <div className="one column stackable ui grid">
                                <div className="column">
                                    <button
                                        disabled={submitting || pristine} 
                                        className='btn btn-primary'>{submitting ? 'Saving...' : 'Submit'}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            />
        )
    }

    const renderVote = () => {
        const type = 'proposal';
        const prevVotes = votes && votes[0];
        
        let lastVote = {value: ''};
        if (!_.isEmpty(prevVotes) && !_.isEmpty(prevVotes)) {
            lastVote = prevVotes;
        } 
        return(
            <div className="flex flex-row items-center gap-3">
            {props.isSignedIn && platformed ? (<StarIconSolid
                className="h-10 w-10 text-purple-600 cursor-pointer hover:text-gray-900" 
                onClick={() => handleStar(id)}
                />) :
                props.isSignedIn && (<StarIcon
                    className="h-10 w-10 text-gray-500 cursor-pointer hover:text-gray-900"
                    onClick={() => handleStar(id)}
                />)}
            <button 
                className={`${lastVote.value==="approve"?"disabled:bg-green-500 disabled:text-white":"btn-outline"} btn`} 
                onClick={()=>castVote("approve",type)}
                disabled={lastVote.value==="approve"&&"disabled"}
            >
                <HandThumbUpIcon className="h-7 w-7 mr-2" />
                Support
            </button>
            <button 
                className={`${lastVote.value==="oppose"?"disabled:bg-red-500 disabled:text-white":"btn-outline"} btn`}
                onClick={()=>castVote("oppose",type)}
                disabled={lastVote.value==="oppose"&&"disabled"}
            >
                <HandThumbDownIcon className="h-7 w-7 mr-2" />
                Oppose
            </button>
            </div>
        )
    }

    const renderJoinToVote = () => {

        return(
            <>
            <div className="divider"></div>
            <button 
                className="btn-outline btn mr-2"
                onClick={()=>handleJoinToVoteClick("approve")}
            >
                <HandThumbUpIcon className="h-7 w-7 mr-2" />
                Support
            </button>
            {/* <button 
                className="btn-outline btn"
                onClick={()=>handleJoinToVoteClick("oppose")}
            >
                <HandThumbDownIcon className="h-7 w-7 mr-2" />
                Oppose
            </button> */}
            </>
        )
    }

    const handleJoinToVoteClick = (type="approve") => {
        props.showModal(`vote-${type}`);
    }

    const renderLoggedoutMessage = () => {
        if (!props.auth || !props.auth.userId) {
            return(
                <div className="ui warning message">
                    <div className="header">
                        You are not logged in.

                    </div>
                    <p>
                        <Link to="/login">Log in</Link> to vote and participate in the discussion.
                    </p>
                </div>
            )
        }
    }

    const publicItem = fromOCD(proposal.public);

    const renderNavigation = () => {
        // if logged in and user has history, show back button
        if (props.auth && props.auth.userId && location.key) {
            return (
                <div className='flex flex-row justify-between items-center'>
                    <Link to='/' className="btn btn-ghost btn-square" onClick={() => history.back()}>
                        <ArrowLeftIcon width={8} height={8} className='w-8 h-8'/>
                    </Link>
                    {renderEditButton()}
                </div>
            )
        } else {
            return <Breadcrumb ocd={publicItem.breadcrumb} />
        }
    }

    const renderDrafters = (partisan) => {
        if (!partisan) {
            return null;
        }

        if (!partisan.length) {
            return <PartisanLine partisan={partisan} key={partisan._id}/>
        }

        return partisan.map((p) => {
            return <PartisanLine partisan={p} key={p._id}/>
        })

    }

    const renderSupportCount = () => {
        const approvalCount = approve;
        return (
            <div className="btn btn-circle btn-outline btn-sm">
                { approvalCount > 0 ? approvalCount : 0}
            </div>
        )
    }

    const renderSupporters = () => {
        if (!votes || _.isEmpty(votes) || !votes[0]?._partisan?.name) {
            return null;
        }

        return (
            <>
            {(votes.map((v) => {
                return v.value==='approve' && <PartisanLine partisan={v._partisan} key={v._id}/>
            }))}
            </>
        )
    }

    return(
        <>
        <Galea 
            title={title}
            description={description}
            url={`${process.env.REACT_APP_DOMAIN}${location.pathname}`}
        />
        <RecordEvent 
            hitType='pageview' 
            page={window.location.pathname} 
            title={title}
        />
        <div className="ui stackable grid">
            <div className="row centered">
                <div className="ten wide column space-y-8">
                    {renderLoggedoutMessage()}
                    {renderNavigation()}
                    <h1 className="ui header">{title}</h1>
                    <ProposalMap proposal={proposal} />
                    <div className="proposal" dangerouslySetInnerHTML={{ __html: description }} />
                    <div>
                        <h2 className='my-2 text-lg'>Drafters</h2>
                        {renderDrafters(proposal._partisan)}
                    </div>
                    <div>
                        <h2 className='my-2 text-lg'>Supporting Constituents</h2>
                        <div className="flex flex-row gap-2 items-center flex-wrap">
                            {renderSupportCount()}
                            {renderSupporters()}
                        </div>
                    </div>
                    {props.canVote && renderVote()}
                    {!props.auth?.userId && renderJoinToVote()}
                </div>
            </div>
            <div className="row centered">
                <div className='ten wide column'>
                    <h2 className="my-2 text-lg">Discussion</h2>
                    {renderComments()}
                    {renderForm()}
                </div>
            </div>
        </div>
        {!props.auth?.userId && (<VoteModal postGuestVote={props.postGuestVote} proposal={proposal} />)}
        <BottomNav />
        </>
    )
}

function mapStateToProps(state, ownProps) {
   
    const canVote = state.auth && state.auth.partisan && state.auth.identity && state.auth.identity === state.auth.partisan;

    // if signed in, get partisan id
    const partisan = state?.auth?.partisan;
    // get partisan platforms
    const platforms = state?.partisan && state?.partisan[partisan]?.platforms;

    return { 
        proposals: state.proposals,
        auth: state.auth,
        canVote,
        isSignedIn: state?.auth?.partisan,
        myPlatforms: platforms
    }
}

export default connect(mapStateToProps, {
    fetchProposal,
    postMessage,
    postVote,
    postGuestVote,
    postVoteConfirmation,
    deleteMessage,
    showModal
})(Item);