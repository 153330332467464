import React from "react";
// input is the destructured version of formProps.input
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

function renderError({error, touched},label) {
    const exclPlace = label ? 'top-8' : 'top-0';
    const errorTextSize = label ? 'text-base' : 'text-sm';
    if (touched && error) {
        return (
            <>
            <div className={`absolute ${exclPlace} right-4 flex items-center text-red-500`}>
                <ExclamationTriangleIcon className="h-8 w-8 mr-1" />
            </div>
            <div className={`text-white bg-red-400 ${errorTextSize} w-fit flex-nowrap py-2 px-4 mt-2 rounded whitespace-nowrap`}>
                {error}
            </div>
            </>
        );
    }
}

const renderInput = ({input, label, meta, size="input", placeholder, inputStyle, autoFocus=false}) => {
    const className = `${size} input-bordered w-full outline-transparent ${meta.error && meta.touched ? 'input-error': ''}`;
    const divClassName = `relative w-full`;
    return (
        <div className={divClassName}>
            <label>{label}</label>
            <input
                // onChange={formProps.input.onChange}
                // value={formProps.value}
                // is the same as
                {...input} 
                autoComplete="off"
                placeholder={placeholder}
                style={inputStyle}
                className={className}
                autoFocus={autoFocus}
            />
            {renderError(meta, label)}
        </div>
        );
}

export default renderInput;