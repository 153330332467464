import { ADD_MODAL, HIDE_MODAL, DELETE_MODAL } from '../actions/types';

const INITIAL_STATE = {
    modal: null,
    visible: false
}

const modalReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADD_MODAL:
            return { ...state, modal: action.payload, visible: true };
        case HIDE_MODAL:
            return { ...state, visible: false };
        case DELETE_MODAL:
            return { ...state, modal: null, visible: false };
        default:
            return state;
    }
};

export default modalReducer;