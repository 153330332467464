import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { fromOCD } from "../../../utils/fromOCD";

import Loader from "../../Loader";
import PartisanLine from "../../partisan/line";

import { connect } from "react-redux";

import { showModal } from "../../../actions";

import { StarIcon } from "@heroicons/react/24/outline";
import { StarIcon as StarIconSolid } from "@heroicons/react/24/solid";

const PropCard = ({loader, proposal, showModal, partisan, myPlatforms, isSignedIn}) => {

   const handleStar = (proposalId) => {
        showModal(`platformProposal-${proposalId}`);
    }

    if (loader && loader.proposals) {
        return <Loader loader='proposals'/>
    }

    if (_.isEmpty(proposal)) {
        return <div>No proposal yet...</div>
    }

    const partisanId = partisan ? partisan : null;
    
    var publicItem = fromOCD(proposal.public);
    var comments = Object.keys(proposal.discussion).length;
    const proposalId = proposal && proposal._id ? proposal._id : null;
    const publicItemUri = publicItem && publicItem.uri ? publicItem.uri : null;
    const author = partisanId && partisanId === proposal._partisan ? true : false;
    // find if user voted for this proposal
    const lastVote = proposal.votes && _.last(proposal.votes.filter(({_partisan})=>(_partisan===partisanId)));
    const lastVoteValue = lastVote && lastVote.value ? lastVote.value : null;

    // is this proposal platformed by the current partisan
    const platformed = myPlatforms && myPlatforms.find(platform => platform.proposals.find(proposal => proposal === proposalId || proposal?._id === proposalId));
    
    // abbreviate proposal description and strip html tags
    const description = proposal.description && proposal.description.length > 200 ? proposal.description.substring(0, 200).replace(/(<([^>]+)>)/gi, "") + '...' : proposal.description.replace(/(<([^>]+)>)/gi, "");

    return(
        <div className="card shadow-md flex flex-col mb-3 border rounded-none" key={proposalId}>
            <div className="card-body p-4 items-start">
                <PartisanLine partisan={proposal._partisan} noLink={true}/>
                <Link
                    to={`/proposal/${proposalId}`}
                    className="card-title"
                >
                    <h3>{proposal.title}</h3>
                </Link>
                {description && <p className="text-sm font-light -mt-2">{description}</p>} 
                <div className="card-actions mb-1">
                    {isSignedIn && platformed ? (<StarIconSolid
                        className="h-5 w-5 text-gray-500 cursor-pointer hover:text-gray-900" 
                        onClick={() => handleStar(proposalId)}
                        />) :
                        isSignedIn && (<StarIcon
                            className="h-5 w-5 text-gray-500 cursor-pointer hover:text-gray-900"
                            onClick={() => handleStar(proposalId)}
                        />)}
                    <div className="badge badge-primary">
                        <Link
                            to={`/publics${publicItemUri}`}
                            className="hover:text-white"
                        >
                            <b>{publicItem.name} {`${publicItem.type!=="place"?publicItem.type:''}`}</b>
                        </Link>
                    </div>
                    {author && <span className="badge badge-outline">author</span>}
                    {comments > 0 ? <div className="badge badge-ghost">
                        {comments} {`${comments===1?'Comment':'Comments'}`}
                    </div> : null}
                    {proposal.approve > 0 ? <div className="badge badge-ghost">
                        {proposal.approve} {`${proposal.approve===1?'Supporter':'Supporters'}`}
                    </div> : null}
                    {lastVote && <span className="badge badge-ghost">{lastVoteValue}</span>}
                </div>
            </div>
        </div>
    )

}

const mapStateToProps = (state) => {

    // if signed in, get partisan id
    const partisan = state?.auth?.partisan;
    // get partisan platforms
    const platforms = state?.partisan && state?.partisan[partisan]?.platforms;

    return {
        isSignedIn: state?.auth?.partisan,
        myPlatforms: platforms,
    }
}

export default connect(mapStateToProps, { showModal })(PropCard);