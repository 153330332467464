import React from 'react';

import { Form, Field } from 'react-final-form';

import renderInput from '../forms/CustomInput';
import renderTextarea from '../forms/CustomTextarea';

import VotePending from './pending';

const Vote = ({type, proposal, postGuestVote, onDismiss}) => {

    const [pending, setPending] = React.useState(false);

    const onSubmit = async (formValues) => {
        await postGuestVote(formValues, proposal._id);
        setPending(true);
    }

    if (pending) return <VotePending onDismiss={onDismiss} />

    return (
        <div className='gap-4 flex flex-col'>
            <p>Check your email and tap the link to verify your vote. Your name will appear on the proposal page.</p>
            <Form
                initialValues={{ email: '', name: '', address: '', comment: '', vote: type }}
                onSubmit={onSubmit}
                validate={formValues => {
                    const errors = {};

                    if (!formValues.name) {
                        errors.name = 'A name is required';
                    }

                    if (!formValues.email) {
                        errors.email = 'An email address is required';
                    }

                    // validate email address format
                    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                    if (formValues.email && !emailRegex.test(formValues.email)) {
                        errors.email = 'Invalid email address';
                    }

                    if (!formValues.address) {
                        errors.address = 'An address (or ZIP code) is required';
                    }

                    // validate address format
                    const addressRegex = /^\d{5}(?:[-\s]\d{4})?$/;
                    if (formValues.address && !addressRegex.test(formValues.address)) {
                        errors.address = 'Valid ZIP code required';
                    }

                    return errors;
                }}
                render={({ handleSubmit, submitting, pristine }) => (
                    <form onSubmit={handleSubmit} className="w-full mr-6 gap-4 flex flex-col">
                        <Field
                            name="name"
                            component={renderInput}
                            type="text"
                            placeholder="Joe Ray"
                            label="Your name"
                            searchAction={handleSubmit}
                            required
                        />
                        <Field
                            name="email"
                            component={renderInput}
                            type="text"
                            placeholder="Email address"
                            label="Email address"
                            searchAction={handleSubmit}
                            required
                        />
                        <Field
                            name="address"
                            component={renderInput}
                            type="text"
                            placeholder="ZIP Code"
                            label="ZIP Code"
                            searchAction={handleSubmit}
                            required
                        />
                        <Field
                            name="comment"
                            component={renderTextarea}
                            type="text"
                            label={`Why do you ${type} this proposal? (optional)`}
                            searchAction={handleSubmit}
                        />
                        <Field 
                            name="vote"
                            component="input"
                            type="hidden"
                        />
                        <button 
                            className="btn btn-primary w-full mt-2" 
                            type="submit"
                            disabled={submitting || pristine}
                            >
                                {type && type === 'oppose' ? 'Oppose this proposal' : 'Support this proposal'}
                            </button>
                    </form>
                )}
            />
        </div>
    );
}

export default Vote;