import React from "react";
import { useEffect, useState } from "react";

import { connect } from "react-redux";
import { fetchPartisanProposals, getPartisanPlatforms } from "../../actions";
import { fromOCD } from "../../utils/fromOCD";

import { useParams } from "react-router-dom";

import ListProposal from "../proposal/listProposal";
import ListPlatform from "../platforms/listPlatform";

import BottomNav from "../navigation/mobilenav";

import Loader from "../Loader";
import Galea from "../../utils/Galea";
import RecordEvent from "../analytics/pageview";

const Partisan = (props) => {

    const { alias } = useParams();
    const [mounted, setMounted] = useState(false);
    
    useEffect(() => {
        if (!mounted || alias !== mounted) {
            props.fetchPartisanProposals(alias);
            props.getPartisanPlatforms(alias);
            setMounted(alias);
        }
    }, [mounted, props, alias]);

    // get pagePartisan from alias
    let id = props.pagePartisans && Object.keys(props.pagePartisans).find((key) => {
        return props.pagePartisans[key].alias === alias;
    });

    // if pagePartisan not found, try alias as id
    if (!id) {
        id = alias;
    }
    
    const pagePartisan = props.pagePartisans[id]
    if (!pagePartisan || !pagePartisan._id) {
        return <Loader loader='profile' />
    }
    
    const {name} = pagePartisan;
    const publicItem = pagePartisan.public && fromOCD(pagePartisan.public);
    const canonicalUrl = pagePartisan?.alias ? `${process.env.REACT_APP_DOMAIN}/${pagePartisan.alias}` : `${process.env.REACT_APP_DOMAIN}/partisan/${pagePartisan._id}`;
    const electoralStatus = () =>{
        switch (parseInt(pagePartisan.electoralStatus)) {
            case 3:
                return 'Running for office';
            case 4:
                return 'Elected';
            case 5:
                return 'Incumbent';    
            default:
                return;
        }
    }
    return(
        <>
        <Galea
            title={`${name} of ${publicItem?.fullname}`}
            description={`${name} of ${publicItem?.fullname}'s political platform and proposals.`}
            url={canonicalUrl}
        />
        <RecordEvent 
            hitType='pageview' 
            page={window.location.pathname} 
            title={`${name} of ${publicItem?.fullname}`}
        />
        <div className="flex flex-col w-full items-center">
            <div className="flex flex-col p-4 w-full sm:w-8/12 gap-4">
                <div className="flex flex-col items-center gap-2">
                    <h1 className="text-2xl font-bold">{pagePartisan.name}</h1>
                    {publicItem?.fullname && (<div><i className="icon map marker"></i>{publicItem?.fullname}</div>)}
                    {pagePartisan?.office && (<div>{pagePartisan?.office}</div>)}
                    {electoralStatus() ? <div className="bg-purple-100 px-2 py-1 rounded">{electoralStatus()}</div> : null}
                    <div>{pagePartisan.bio}</div>
                </div>
                {pagePartisan.platforms && (
                <>
                    <h2>Priorities</h2>
                    <div className="flex flex-wrap">
                    <ListPlatform
                        platforms={pagePartisan.platforms}
                        proposals={pagePartisan.proposals}
                        isSignedIn={props.isSignedIn}
                        partisan={id}
                    />
                    </div>
                </>)}
                <h2>Proposals</h2>
                <ListProposal 
                    proposals={pagePartisan.proposals}
                    isSignedIn={props.isSignedIn}
                    partisan={id}
                />
            </div>
        </div>
        <BottomNav />
        </>
    )
}

const mapStateToProps = (state, ownProps) => {

    const pagePartisans = state.pagePartisan ? state.pagePartisan : null;

    return {
        pagePartisans
    }
}

export default connect(mapStateToProps, {
    fetchPartisanProposals, getPartisanPlatforms
})(Partisan);