import { 
    SET_LOADER
} from '../actions/types';

const INITIAL_STATE = {
    pusher: true,
    page: false,
    proposal: false
}

const loaderReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LOADER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default loaderReducer;