import React from "react";
import ReactDOM from "react-dom";


const Loader = ({loader, onDismiss}) => {

    switch (loader) {
        case 'platformsList':
            return (
                <div className="flex flex-col w-full items-center">
                <div className="flex flex-col p-4 w-full sm:w-6/12">
                    <div className="animate-pulse flex w-full gap-4 flex-col items-center mx-auto">
                        <div className="h-6 bg-slate-300 rounded w-6/12"></div>
                        <div className="h-2 bg-slate-300 rounded w-4/12"></div>
                        <div className="h-2 bg-slate-300 rounded w-4/12"></div>
                    </div>
                </div>
            </div>
            )
        case 'profile':
            return (
                <div className="flex flex-col w-full items-center">
                    <div className="flex flex-col p-4 w-full sm:w-6/12">
                        <div className="animate-pulse flex w-full gap-4 flex-col items-center mx-auto">
                            <div className="h-6 bg-slate-300 rounded w-6/12"></div>
                            <div className="h-2 bg-slate-300 rounded w-4/12"></div>
                            <div className="h-2 bg-slate-300 rounded w-4/12"></div>
                        </div>
                    </div>
                </div>
            );
        case 'proposalDetail':
            return (
                <div className="flex flex-col w-full items-center">
                    <div className="flex flex-col p-8 w-full sm:w-8/12 mt-12">
                        <div className="animate-pulse flex w-full items-start gap-4 flex-col mx-auto">
                            <div className="h-6 bg-slate-300 rounded w-9/12"></div>
                            <div className="h-6 bg-slate-300 rounded w-8/12"></div>
                            <div className="h-2 bg-slate-300 rounded w-11/12"></div> 
                            <div className="h-2 bg-slate-300 rounded w-11/12"></div> 
                            <div className="h-2 bg-slate-300 rounded w-6/12"></div>
                        </div>
                    </div>
                </div>
            );
        case 'proposals':
        // return following JSX 3 times
            const loadingElement = (
                <div className="card shadow-md flex flex-col mb-3 border rounded-none">
                    <div className="ui placeholder m-4">
                        <div className="header">
                            <div className="line"></div>
                            <div className="line"></div>
                        </div>
                        <div className="content mb-4">
                            <div className="line"></div>
                        </div>
                    </div>
                </div>
            );
            
            return (
                <div>
                    {loadingElement}
                    {loadingElement}
                    {loadingElement}
                </div>
            );
        case 'feed':
            // return following JSX 3 times
                const loadingFeedElement = (
                    <div className="card shadow-md flex flex-col mb-3 border rounded-none">
                        <div className="ui placeholder m-4">
                            <div className="header">
                                <div className="line"></div>
                                <div className="line"></div>
                            </div>
                            <div className="content mb-4">
                                <div className="line"></div>
                            </div>
                        </div>
                    </div>
                );
                
                return (
                    <div>
                        {loadingFeedElement}
                        {loadingFeedElement}
                        {loadingFeedElement}
                    </div>
                );
        default:
            return ReactDOM.createPortal(
                <div onClick={onDismiss} className="ui dimmer modals visible active" style={{position: "fixed", overflow: "auto"}}>
                    <div className="ui active inverted dimmer">
                        <div className="ui small text loader">Loading</div>
                    </div>
                </div>,
                document.querySelector('#loader')
            );
    }
};

export default Loader;