import React from 'react';
import Modal from '../../Modal';

import { connect } from 'react-redux';

import { Form, Field } from 'react-final-form';
import CustomInput from '../../forms/CustomInput';

import { getAuthPartisans, postNewPartisan, switchPartisan, showModal, hideModal } from '../../../actions';

import PartisanLine from '../../partisan/line';

// check heroicon 
import { CheckCircleIcon, PlusCircleIcon } from '@heroicons/react/24/outline';

const SwitchPartisan = (
    {
        partisan: currentPartisan, 
        partisanList, 
        modal, 
        getAuthPartisans, 
        postNewPartisan,
        switchPartisan,
        hideModal
    }) => {

    const [mounted, setMounted] = React.useState(false);
    const [showForm, setShowForm] = React.useState(false);

    React.useEffect(() => {
        if (!mounted && partisanList?.length === 0) {
            setMounted(true);
            getAuthPartisans();
        }
    }, [getAuthPartisans, mounted, partisanList]);

    // don't render if no partisans
    if (!currentPartisan) {
        return null;
    }

    const renderPartisans = () => {
        if (partisanList) {
            return partisanList.map(partisan => {
                const isCurrentProfile = currentPartisan === partisan._id ? true : false;
                return (
                    <div 
                        key={partisan._id} 
                        className="flex flex-row justify-between items-center border-b border-gray-200 py-2"
                        >
                        <div className="flex flex-row items-center justify-center">
                            {isCurrentProfile ? (
                            <>
                                <div className='w-9 my-1'>
                                    <CheckCircleIcon className="h-7 w-7 text-yellow-500" />
                                </div>
                                <div>
                                    <div className="text-base font-semibold">{partisan.name}</div>
                                </div>
                            </>
                            ) : (<PartisanLine partisan={partisan} noLink />)}
                        </div>
                        <div className="flex flex-row">
                            {!isCurrentProfile && (
                                <button 
                                    className="btn btn-primary btn-sm" 
                                    onClick={() => handleSwitch(partisan._id)}>
                                        Switch
                                </button>
                            )}
                        </div>
                    </div>
                );
            });
        }
    }

    const handleAddProfileSubmit = async (values) => {
        await postNewPartisan(values);
        setShowForm(false);
        hideModal();
    }

    const renderAddProfileForm = () => {
        const initialValues = {
            name: '',
            newAcct: '_new_'
        }
        return (
            <Form
                onSubmit={handleAddProfileSubmit}
                initialValues={initialValues}
                validate={formValues => {
                    const errors = {};

                    if (!formValues.name) {
                        errors.name = 'A name is required';
                    }

                    if (!formValues.address || isNaN(formValues.address) || formValues.address.length < 5) {
                        errors.address = 'A ZIP code is required';
                    }

                    return errors;
                }}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit} className='w-fit'>
                        <div className='flex flex-row justify-between items-start w-full gap-2'>
                            <Field 
                                name="name" 
                                component={CustomInput} 
                                placeholder="Name for profile" 
                                size="input-sm" 
                                autoFocus 
                            />
                            <Field 
                                name="address" 
                                component={CustomInput} 
                                placeholder="ZIP Code" 
                                size="input-sm" 
                            />
                            <Field
                                name='newAcct'
                                component='input'
                                type='hidden'
                            />
                            <button 
                                type="submit" 
                                disabled={submitting || pristine} 
                                className="ml-2 btn btn-primary btn-sm">
                                Next
                            </button>
                        </div>
                    </form>
                )}
            />
        );
    }

    const renderAddPartisan = () => {
        return (
            <div className="flex flex-row justify-between items-center border-b border-gray-200 py-2 w-full">
                {showForm ? renderAddProfileForm() : (
                    <div className="flex flex-row items-center justify-center cursor-pointer" onClick={handleAddProfile}>
                        <div className='w-9'>
                            <PlusCircleIcon className="h-7 w-7 text-gray-500" />
                        </div>
                        <div>
                            <div className="text-base">Add Profile</div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    const handleAddProfile = async () => {
        setShowForm(true);
    }

    const handleSwitch = (id) => {
        switchPartisan(id);
        hideModal();
    }

    const renderContent = () => {
        return (
            <div className="flex flex-col">
                <div className="flex flex-col">
                    <div className="flex flex-row justify-between">
                        <div className="flex flex-col">
                            <div className="text-sm">Select an profile or add a new one.</div>
                        </div>
                    </div>
                    <div className="flex flex-col mt-4">
                        <div className="flex flex-col mt-2">
                            {renderPartisans()}
                            {renderAddPartisan()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (!partisanList || !modal.visible || modal.modal !== 'SWITCH_PARTISAN') {
        return null;
    }

    return (
        <Modal
            title="Switch Profile"
            content={renderContent()}
            onDismiss={() => hideModal()}
        />
    );

}

const mapStateToProps = (state) => {

    const partisans = state.auth && state.auth.partisanList ? Object.values(state.auth.partisanList) : null;

    return {
        partisan: state.auth.partisan,
        partisanList: partisans,
        modal: state.modal
    }
}

export default connect(mapStateToProps, 
    { getAuthPartisans, postNewPartisan, switchPartisan, showModal, hideModal })
    (SwitchPartisan);