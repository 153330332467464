import React from "react";
import { Route, Routes } from 'react-router-dom';

import Country from "./country"; 
import State from "./state";
import County from "./county";
import Place from "./place";
import CongressionalDistrict from "./congressionalDistrict";

import Map from "./map";

const PublicsRouter = (props) => {

    const toOCD = (rawObject) => {
        const partisanOCD = Object.entries(rawObject).map(([key,value])=>{
            return `${key}:${value}`
        });
        return `ocd-division/${partisanOCD.join('/')}`;
    }

    return (
        <>
        <Routes basename="/publics">
            <Route 
                path="/:country"  
                element={<Country toOCD={toOCD} />}
                toOCD={toOCD}
            />
            <Route path="/:country/:state"  
                element={<State toOCD={toOCD} />}
                toOCD={toOCD}                    
                />
            <Route path="/:country/:state/cd/:cd"  
                element={<CongressionalDistrict toOCD={toOCD} />}
                toOCD={toOCD}
                />
            <Route path="/:country/:state/county/:county"  
                element={<County toOCD={toOCD} />}
                toOCD={toOCD}
                />
            <Route path="/:country/:state/place/:place"  
                element={<Place toOCD={toOCD} />}
                />
        </Routes>
        <Map />
    </>
    )
}

export default PublicsRouter;