import { 
    GET_PARTISAN,
    FETCH_PARTISAN_PROPOSALS,
    FETCH_PARTISAN_PLATFORMS
} from '../actions/types';

const partisanPageReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_PARTISAN:
            return { ...state, [action.payload._id]: {
                ...state[action.payload._id],
                ...action.payload
            } 
        }
        case FETCH_PARTISAN_PROPOSALS:
            return { ...state, [action.payload._id]: {
                ...state[action.payload._id],
                ...action.payload
            } 
        }
        case FETCH_PARTISAN_PLATFORMS:
            return { ...state, [action.payload._id]: {
                ...state[action.payload._id],
                ...action.payload
            } 
        }
        default:
            return state;
    }
};

export default partisanPageReducer;