import React from 'react';

import { InboxArrowDownIcon } from '@heroicons/react/24/outline';

const VotePending = ({proposal}) => {
    return (
        <div className="flex flex-col items-center justify-center gap-4">
            <div className="flex flex-row items-center justify-center">
                <InboxArrowDownIcon className="h-12 w-12 text-purple-700" />
            </div>
            <div>
                <h3>Thank you for your vote!</h3>
                <p>Please check your email and click the link to verify your vote.</p>
            </div>
        </div>
    );
}

export default VotePending;