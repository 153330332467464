import _ from 'lodash';

export function fromOCD(OCDstring) {
    const publicRes = OCDstring.split('/');

    // map over publicRes and split on ':' and join on '/', except on the third item
    // which is the name of the place
    const uri = publicRes.map((item,i) => {
            if (i === 3) {
                return item.split(':').join('/');
            }
            return item.split(':')[1];
        }).join('/');

    let fullname = '';
    if (publicRes.length === 4) {
        fullname = `${_.startCase(_.last(publicRes).split(':')[1])}, ${_.upperCase(publicRes[2].split(':')[1])}`;
    } else {
        fullname = _.last(publicRes).split(':')[1];
    }

    return ({
        fullname,
        name: _.last(publicRes).split(':')[1],
        type: _.last(publicRes).split(':')[0],
        breadcrumb: publicRes.map((item,i) => {
            if (item === 'ocd-division') {
                return {};
            }
            let breadUri = publicRes.map((item,i) => {
                if (i === 3) {
                    return item.split(':').join('/');
                }
                return item.split(':')[1];
            }).slice(0,i+1).join('/');
            return {
                name: item.split(':')[1],
                type: item.split(':')[0],
                uri: `/publics${breadUri}`
            }
        }),
        uri
    });
}