import React, { useEffect, useState } from "react";

// import heroicon of back arrow
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

import { TailSpin } from "react-loader-spinner";

// input is the destructured version of formProps.input

function renderError({error, touched}) {
    if (error) {
        return (
            <>
            <div className="absolute top-8 right-4 flex items-center text-red-500">
                <ExclamationTriangleIcon className="h-8 w-8 mr-1" />
            </div>
            <div className="text-white bg-red-400 w-fit flex-nowrap py-2 px-4 mt-2 rounded whitespace-nowrap">
                {error}
            </div>
            </>
        );
    }
}

const LiveCheckInput = ({input, label, meta, placeholder, disabled, handleClick, searchAction, autoFocus, prefixPlaceholder}) => {

    const [term, setTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [activeInput, setActiveInput] = useState(false);

    // spinning loader
    const loader = (
        <div className="mr-1">
            <TailSpin
                color="#00BFFF"
                height={26}
                width={26}
                timeout={4000} //4 secs
            />
        </div>
    );

    const inputPadding = prefixPlaceholder ? 'pl-24' : 'pl-4';

    const className = `input input-bordered w-full placeholder:text-gray-500 ${inputPadding} ${meta.error && meta.touched ? 'input-error': ''}`;
    const divClassName = `relative w-full`;

    const searchIcon = (
        <div 
            onClick={handleClick} 
            className="absolute top-8 right-4 flex text-gray-500 cursor-pointer">
            {meta.error ? <ExclamationTriangleIcon className="h-8 w-8 mr-1" /> : 
                loading ? loader : input.value && <CheckCircleIcon className="h-8 w-8 mr-1 text-green-600" />}
        </div>
    );

    const prefix = (
        <div id="prefix" className="absolute top-9 left-4 flex items-center text-gray-500">
            {prefixPlaceholder}
        </div>
    );

    useEffect(() => {
        const search = () => searchAction(input.value);
        setLoading(true);
        if (!activeInput) {
            setLoading(false);
            return;
        }
        if (term && term.length > 1 && term !== input.value && !meta.error) {
            const timeoutId = setTimeout(() => {
                // if term is not in options, search
                if (input.value && input.value.length > 2 && term !== input.value) {
                    setTerm(input.value);
                    search();
                }
                setLoading(false);
            }, 500);
            // react will run the return when component is re-reendered.
            return () => {
                setLoading(false);
                clearTimeout(timeoutId);
            }
        } else {
            setTerm(input.value);
            setLoading(false);
        }

    }, [term, input, searchAction, activeInput, meta.error]);

    return (
        <div className={divClassName}>
            <label htmlFor={input.name}>{label}</label>
            <input
                // onChange={formProps.input.onChange}
                // value={formProps.value}
                // is the same as
                {...input} 
                id={input.name}
                autoComplete="off"
                placeholder={placeholder}
                disabled={disabled}
                className={className}
                autoFocus={autoFocus}
                onFocus={() => setActiveInput(true)}
                onBlur={() => setActiveInput(false)}
            />
            {prefix}
            {searchIcon}
            {renderError(meta)}
        </div>
        );
}

export default LiveCheckInput;