import React from 'react';
import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { fetchUser, showModal, fetchProposalsByAddress } from '../../actions';
import { Field, Form } from 'react-final-form';

import Identity from '../identity';
import ProfileImage from '../partisan/image';
import renderSearchInput from '../forms/CustomSearchInput';

import SwitchPartisan from '../partisan/switch';

import { ArrowRightOnRectangleIcon, PlusIcon } from '@heroicons/react/24/solid';

const Navigation = (props) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) {
      props.fetchUser();
      setMounted(true);
    }
  }, [mounted, props]);

  const renderName = () => {
    if (!props.partisan || !props.partisan.name) {
      return;
    }
    return props.partisan.name;
  };

  const onSubmit = (formValues) => {
    fetchProposalsByAddress(formValues.address);
  };

  const renderSearch = () => {
    return (
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="w-full mr-6 hidden">
            <Field
              name="address"
              component={renderSearchInput}
              type="text"
              placeholder="Search"
              searchAction={handleSubmit}
            />
          </form>
        )}
      />
    );
  };

  // toggle login state
  const handleClick = (e) => {
    // prevent default behavior
    e.preventDefault();
    props.showModal('login');
  };

  const onProfileChangeClick = () => {
    props.showModal('SWITCH_PARTISAN');
  };

  const renderCreateMenu = () => {
    return (
      <div className="dropdown dropdown-bottom hidden sm:flex justify-center">
        <label
          tabIndex={0}
          className="m-0 self-start cursor-pointer btn btn-primary w-40"
        >
          <PlusIcon width={7} height={7} className="w-7 h-7 mr-3" /> Create
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content z-[1] menu p-2 shadow bg-base-200 rounded-box w-40 gap-2 items-center"
        >
          <li className="w-full">
            <Link
              className="w-full items-center flex flex-col"
              to="/create/post"
            >
              <div>Post</div>
            </Link>
          </li>
          <li className="w-full">
            <Link
              className="w-full items-center flex flex-col"
              to="/create/proposal"
            >
              Proposal
            </Link>
          </li>
        </ul>
      </div>
    );
  };

  const renderUserDropdown = () => {
    if (!props.auth || !props.auth.userId) {
      return (
        <div>
          <Link to="/login" onClick={handleClick} className="btn btn-primary">
            <ArrowRightOnRectangleIcon className="h-6 w-6 mr-2" /> Login
          </Link>
        </div>
      );
    } else {
      const ProfileLink = props.auth?.alias
        ? `/${props.auth.alias}`
        : props.auth?.partisan && `/profile/${props.auth.partisan}`;

      return (
        <div className="mt-0 flex-none">
          <div className="dropdown dropdown-end dropdown-hover cursor-pointer">
            <div tabIndex={0} className="m-0">
              <Link to={ProfileLink}>
                <ProfileImage athlete={props.athlete} className="w-12 h-12" />
              </Link>
            </div>
            <ul
              tabIndex={0}
              className="dropdown-content menu p-2 shadow bg-slate-100 rounded-box w-48 z-20 "
            >
              <li className="self-center p-2 border-b text-slate-400">
                {renderName()}
              </li>
              <li>
                <Link to="/edit/partisan">Edit Profile</Link>
              </li>
              {props.auth?.permission > 1 && (
                <li>
                  <Link onClick={onProfileChangeClick}>Switch Profile</Link>
                </li>
              )}
              <li>
                <a href="/api/logout">Log out</a>
              </li>
            </ul>
          </div>
          <SwitchPartisan />
        </div>
      );
    }
  };

  return (
    <div className="grid grid-cols-3 sm:grid-cols-3 gap-4 m-4 md:gap-x-24">
      <div>
        <Link to="/">
          <Identity />
        </Link>
      </div>
      <div>
        {renderSearch()}
        {props.auth?.userId && renderCreateMenu()}
      </div>
      <div className="text-right">{renderUserDropdown()}</div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    partisan: state.partisan[state.auth.partisan],
  };
}

export default connect(mapStateToProps, {
  fetchUser,
  showModal,
  fetchProposalsByAddress,
})(Navigation);
