import React from "react";
// input is the destructured version of formProps.input

function renderError({error, touched}) {
    if (touched && error) {
        return (
            <div className="ui error message">
                <div className="header">{error}</div>
            </div>
        );
    }
}

function renderOptions(options) {
    return options.map(option=>{
        return (
            <option 
                key={option.value}
                value={option.value}
            >
                {option.name}
            </option>
        )
    })
}

const renderDropdown = ({input, label, meta, placeholder, options, disabled}) => {
    const className = `${meta.error && meta.touched ? 'error': ''}`

    return (
        <div className={className}>
            <label>{label}</label>
            <select 
                className="select select-bordered w-full"
                // onChange={formProps.input.onChange}
                // value={formProps.value}
                // is the same as
                {...input} 
                placeholder={placeholder}
                autoComplete="off"
                disabled={disabled}
            >
                 {renderOptions(options)}
            </select>
            {renderError(meta)}
        </div>
    );
}

export default renderDropdown;