import React from 'react';

import Navigation from './navigation';
import Footer from './footer';

import LoginModal from './auth/loginModal';
import Login from './auth/login';

import CheckEmailNotice from './auth/email';

import Home from './home';
import Partisan from './partisan';
import EditPartisan from './partisan/edit';
import EditProposal from './proposal/edit';

import EditPost from './post/edit';
import ViewPost from './post/view';

import ManagePlatforms from './platforms/manage';

import Analytics from './analytics';

import PublicsRouter from './publics/';
import ProposalRouter from './proposal';
import PageRouter from './pages';

import Notice from './Notice';

import { Routes, Route } from 'react-router-dom';
import CustomRouter from './customRouter';
import history from '../history';

import { connect } from 'react-redux';

import './App.css';

const App = ({auth}) => {

    return (
        <div>
            <Notice />
            <CustomRouter history={history}>
                <Navigation />
                <Routes>
                    <Route path="/*" element={<Home />}/>
                    <Route path="login" element={<Login />}/>
                    <Route path="login/email_sent" element={<CheckEmailNotice />}/>
                    <Route path="create/proposal" element={<EditProposal />}/>
                    <Route path="edit/proposal/:id" element={<EditProposal />}/>
                    <Route path="publics/*" element={<PublicsRouter />}/>
                    <Route path="proposal/*" element={<ProposalRouter />}/>
                    <Route path="create/post" element={<EditPost />}/>
                    <Route path="edit/post/:id" element={<EditPost />}/>
                    <Route path="pages/*" element={<PageRouter />}/>
                    {(auth?.userId) && (<Route path="edit/partisan" element={<EditPartisan />}/>)}
                    <Route path="partisan/:alias" element={<Partisan />}/>
                    <Route path=":alias/" element={<Partisan />}/>
                    <Route path=":alias/posts/:id" element={<ViewPost />}/>
                </Routes>
                <div className="spacer"></div>
                <Footer />
                {auth?.partisan && (<ManagePlatforms partisanId={auth.partisan} />)}
                <LoginModal />
                <Analytics />
            </CustomRouter>
        </div>
    );
}

const mapStateToProps = (state) => {

    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(App);