import React from "react";

import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="ui vertical footer segment invisible sm:visible">
            <div className="grid grid-cols-2 w-full gap-4 px-6">
                <div className="">
                    <div className="logo-footer">
                        <span>S</span>
                    </div>
                    <h4 className="mt-7 mb-2 text-xl">Speeqr.org</h4>
                    <p>A platform for mobilizing democratic action and better governance.</p>
                    <div className="flex flex-row gap-4 mt-4">
                        <Link to={'/pages/how-this-works'}>How this works</Link>
                        <div className="text-gray-400">|</div>
                        <Link to={'/pages/free-campaign-page'}>Free campaign page</Link>
                        <div className="text-gray-400">|</div>
                        <Link to={'/pages/claim-profile'}>Claim your profile</Link>
                    </div>
                </div>
                <div className="text-right w-full">
                    <h4 className="ui header">Contact</h4>
                    <p className="text-right">hello@speeqr.org</p>
                </div>
            </div>
        </div>
    );
}

export default Footer;
                        
                        