import _ from "lodash";
import React from "react";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ListProposal from "../proposal/listProposal";
import Breadcrumb from "../navigation/breadcrumb";

import { connect } from "react-redux";
import { fetchUser, fetchProposals } from "../../actions";

import { toOCD } from "../../utils/toOCD";
import { fromOCD } from "../../utils/fromOCD";
import Galea from "../../utils/Galea";

const County = ({auth, loader, partisan, proposals, fetchProposals}) => {

    const [mounted, setMounted] = useState(false);
    const { country, state, county } = useParams();

    const placeObj = {
        country,
        state,
        county,
    }

    const ocd = toOCD(placeObj);
    const ocdObject = fromOCD(ocd);
    const filteredProposals = _.filter(proposals, {public: ocd});

    useEffect(() => {
        if (!mounted) {
            fetchProposals(ocd);
            setMounted(true);
        }
    }, [mounted, ocd, fetchProposals]);

    const isSignedIn = () => {
        if (!auth || !auth.userId) {
            return
        } else {
            return true
        }
    }

    return(
        <>
        <Galea 
            title={`${_.upperCase(ocdObject.name)} County Proposals`}
            description={`Proposals in ${_.upperCase(ocdObject.name)}`}
            url={`${process.env.REACT_APP_DOMAIN}/publics${ocdObject.uri}`}
        />
        <div className="ui stackable grid">
            <div className="row centered">
                <div className="ten wide column">
                    <Breadcrumb ocd={ocdObject.breadcrumb} />
                    <h2 className="ui header">
                        <Link to={`/publics${ocdObject.uri}`} style={{color:'#333'}}>
                            Top proposals for {_.startCase(county)} County, {_.upperCase(state)}
                        </Link>
                    </h2>
                </div>
            </div>
            <div className="row centered">
                <div className="ten wide column">
                    <ListProposal 
                        proposals={filteredProposals}
                        loader={loader}
                        isSignedIn={isSignedIn()}
                    />
                </div>
            </div>
        </div>
        </>
    )
}

function mapStateToProps(state) {
    
    return {
        auth: state.auth,
        loader: state.loader,
        proposals: state.proposals,
        partisan: state.partisan[state.auth.partisan]
    }
}

export default connect(mapStateToProps, {
    fetchUser,
    fetchProposals
})(County); 