import { combineReducers } from "redux";
import authReducer from "./authReducer";
import partisanReducer from "./partisanReducer";
import proposalReducer from "./proposalReducer";
import postReducer from "./postReducer";
import partisanPageReducer from "./partisanPageReducer.js";
import noticeReducer from "./noticeReducer";
import feedReducer from "./feedReducer";
import searchReducer from "./searchReducer";
import modalReducer from "./modalReducer";
import loaderReducer from "./loaderReducer";

export default combineReducers({
    auth: authReducer,
    loader: loaderReducer,
    proposals: proposalReducer,
    posts: postReducer,
    partisan: partisanReducer,
    pagePartisan: partisanPageReducer,
    notice: noticeReducer,
    search: searchReducer,
    feed: feedReducer,
    modal: modalReducer
})