import _ from "lodash";
import React from "react";
import Loader from "../../Loader";

import { connect } from "react-redux";

import { showModal } from "../../../actions";

import PropCard from "../propCard";

const ListProposal = (props) => {

   const handleBookmark = (proposalId) => {
        props.showModal(`platformProposal-${proposalId}`);
    }

   const renderList = () => {
        // are we on a partisan page
        const partisanId = props.partisan ? props.partisan : null;
        return Object.values(props.proposals).map(proposal=>{
           return <PropCard key={proposal._id} proposal={proposal} partisanId={partisanId} handleBookmark={handleBookmark}/>
        })
    }

    if (props.loader && props.loader.proposals) {
        return <Loader loader='proposals'/>
    }

    if (_.isEmpty(props.proposals)) {
        return <div>No proposals yet...</div>
    }

    return (
        <div className="proposals">
            {renderList()}
        </div>
    )
}

const mapStateToProps = (state) => {

    // if signed in, get partisan id
    const partisan = state?.auth?.partisan;
    // get partisan platforms
    const platforms = state?.partisan && state?.partisan[partisan]?.platforms;

    return {
        isSignedIn: state?.auth?.partisan,
        myPlatforms: platforms,
    }
}

export default connect(mapStateToProps, { showModal })(ListProposal);