import React, { useEffect, useState } from "react";

import PostCard from "../postCard";

import { connect } from 'react-redux';
import { useParams } from "react-router-dom";

import { fetchPost } from '../../../actions';

import Galea from "../../../utils/Galea";
import RecordEvent from "../../analytics/pageview";

import formatDate from "../../../utils/formatDate";
import { fromOCD } from "../../../utils/fromOCD";

const ViewPost = ({posts, fetchPost}) => {

    const id = useParams().id;
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        if (!mounted && id?.length>0) {
            fetchPost(id);
            setMounted(true);
        }
    }, [mounted, fetchPost, id]);

    if (!posts[id]) {
        return <div>Loading...</div>
    }

    const post = posts[id];

    const { _id, post: postContent, _partisan, public: ocdLine, dateCreated } = post;
    const publicItem = ocdLine ? fromOCD(ocdLine) : {};
    const publicName = publicItem?.name;
    const formattedDate = formatDate(dateCreated);
    const authorName = _partisan?.name;

    // strip tags from post for title and description
    const stripTags = (str) => {
        if ((str===null) || (str===''))
            return false;
        else
            str = str.toString();
        return str.replace(/(<([^>]+)>)/gi, '');
    }
    const postContentNoTags = stripTags(postContent);

    // first 50 characters of post for name
    const title = postContentNoTags?.length > 50 ? postContentNoTags.substring(0, 50) + '...' : postContentNoTags;

    // first 100 characters of post for description
    const description = postContentNoTags?.length > 100 ? postContentNoTags.substring(0, 100) + '...' : postContentNoTags;

    // url
    const url = `${process.env.REACT_APP_DOMAIN}/posts/${_id}`;

    return (
        <>
        <Galea 
            title={`${publicName} - ${title} | Speeqr`}
            description={`On ${formattedDate}, ${authorName} posted: ${description}`}
            url={url}
        />
        <RecordEvent 
            hitType='pageview' 
            page={window.location.pathname} 
            title={`${publicName} - ${title}`}
        />
        <div className="flex flex-col items-center gap-4 m-4">
            <div className="w-full sm:w-8/12">
                <div className="flex flex-col">
                    <PostCard
                        post={post}
                    />
                </div>
            </div>
        </div>
        </>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        posts: state.posts
    }
}

export default connect(mapStateToProps, {
    fetchPost
})(ViewPost);