import React from 'react';

// heroicon for default profile image
import { UserCircleIcon } from '@heroicons/react/24/solid';

const ProfileImage = ({partisan, className}) => {
    if (!partisan || !partisan.profileImages || partisan.profileImages.length === 0) {
        return (<UserCircleIcon className={className} />);
    }
    const { REACT_APP_AWS_S3 } = process.env;
    const { imageFile } = partisan.profileImages[0];
    const imageUri = REACT_APP_AWS_S3 + '/' + imageFile;

    const profileStyle = `${className} rounded-full`;

    return (
        <img src={imageUri} alt="profile" className={profileStyle} />
    );
}

export default ProfileImage;