import _ from "lodash";
import React from "react";
import { useEffect, useState } from "react";

import Galea from "../../../utils/Galea";
import RecordEvent from '../../analytics/pageview';
import PostCard from "../../post/postCard";
import PropCard from "../../proposal/propCard";
import BottomNav from "../../navigation/mobilenav";
import Loader from "../../Loader";

import { connect } from "react-redux";
import { fetchProposalsByAddress, fetchProposals, fetchPostsByAddress, fetchPosts, fetchFeed, clearFeed } from "../../../actions";
import { fromOCD } from "../../../utils/fromOCD";
import PartisanLine from "../../partisan/line";

const Feed = ({loader, auth, partisan, fetchFeed, feed}) => {

    const [mounted, setMounted] = useState(false);
    const [feedOcd, setFeedOcd] = useState([]);
    const [lastFeedOcd, setLastFeedOcd] = useState([]);

    const publics = partisan && partisan[auth.partisan] && !_.isEmpty(partisan[auth.partisan].publics) && Object.keys(partisan[auth.partisan].publics[0]);

    // get country, state, county, place from array of ocds
    const ocdObjects = publics && publics.map((ocd) => {
        return {...fromOCD(ocd), ocd};
    });

    useEffect(() => {
        if (!mounted) {
            setMounted(true);
            fetchFeed({ocd: ''});
        }
    }, [mounted, fetchFeed]);

    useEffect(() => {
        if (!loader.feed && feedOcd === '' && lastFeedOcd !== '') {
            fetchFeed({ocd: ''});
        } else if (!loader.feed && feedOcd === 'country' && lastFeedOcd !== feedOcd) {
            setLastFeedOcd(feedOcd);
            const country = ocdObjects.find((ocd) => {
                return ocd.type === 'country';
            });
            clearFeed();
            fetchFeed({ocd: country.ocd});
        } else if (!loader.feed && feedOcd === 'state' && lastFeedOcd !== feedOcd) {
            setLastFeedOcd(feedOcd);
            const state = ocdObjects.find((ocd) => {
                return ocd.type === 'state';
            });
            clearFeed();
            fetchFeed({ocd: state.ocd});
        } else if (!loader.feed && feedOcd === 'county' && lastFeedOcd !== feedOcd) {
            setLastFeedOcd(feedOcd);
            const county = ocdObjects.find((ocd) => {
                return ocd.type === 'county';
            });
            clearFeed();
            fetchFeed({ocd: county.ocd});
        } else if (!loader.feed && feedOcd === 'place' && lastFeedOcd !== feedOcd) {
            setLastFeedOcd(feedOcd);
            const place = ocdObjects.find((ocd) => {
                return ocd.type === 'place';
            });
            clearFeed();
            fetchFeed({ocd: place.ocd});
        } else if (!loader.feed && feedOcd === 'local' && lastFeedOcd !== feedOcd) {
            setLastFeedOcd(feedOcd);
            const place = ocdObjects.filter((ocd) => {
                // match type to place and county
                return ocd.type === 'place' || ocd.type === 'county';
            });
            // string of ocds
            const ocd = place.map((ocd) => {
                return ocd.ocd;
            });
            clearFeed();
            fetchFeed({ocd});
        }
        setLastFeedOcd(feedOcd);
    }, [feed, ocdObjects, loader, fetchFeed, feedOcd, lastFeedOcd]);

    const renderLoadMoreButton = () => {
        if (feed?.more) {
            return (
                <div className="flex justify-center">
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            if (feedOcd === 'country') {
                                const country = ocdObjects.find((ocd) => {
                                    return ocd.type === 'country';
                                });
                                fetchFeed({ocd: country.ocd, fromPostDate: feed.fromPostDate, fromProposalDate: feed.fromProposalDate});
                            } else if (feedOcd === 'state') {
                                const state = ocdObjects.find((ocd) => {
                                    return ocd.type === 'state';
                                });
                                fetchFeed({ocd: state.ocd, fromPostDate: feed.fromPostDate, fromProposalDate: feed.fromProposalDate});
                            } else if (feedOcd === 'county') {
                                const county = ocdObjects.find((ocd) => {
                                    return ocd.type === 'county';
                                });
                                fetchFeed({ocd: county.ocd, fromPostDate: feed.fromPostDate, fromProposalDate: feed.fromProposalDate});
                            } else if (feedOcd === 'place') {
                                const place = ocdObjects.find((ocd) => {
                                    return ocd.type === 'place';
                                });
                                fetchFeed({ocd: place.ocd, fromPostDate: feed.fromPostDate, fromProposalDate: feed.fromProposalDate});
                            } else if (feedOcd === 'local') {
                                const place = ocdObjects.filter((ocd) => {
                                    // match type to place and county
                                    return ocd.type === 'place' || ocd.type === 'county';
                                });
                                // string of ocds
                                const ocd = place.map((ocd) => {
                                    return ocd.ocd;
                                });
                                fetchFeed({ocd, fromPostDate: feed.fromPostDate, fromProposalDate: feed.fromProposalDate});
                            } else {
                                fetchFeed({ocd: '', fromPostDate: feed.fromPostDate, fromProposalDate: feed.fromProposalDate});
                            }
                        }}
                    >
                        Load More
                    </button>
                </div>
            )
        } else {
            return (
                <div className="flex justify-center w-full">
                    <div className="divider divider-horizontal text-lg w-full">
                        ~
                    </div>
                </div>
            )
        }
    }

    const renderPartisans = () => {
        if (feed?.partisans?.length > 0) {

            const offices = feed.partisans.map((partisan) => {
                return partisan.office;
            });

            // get unique offices
            const uniqueOffices = [...new Set(offices)];

            return uniqueOffices.map((office, i) => {
                return (
                    <div key={i} >
                    <div className="flex flex-row items-center text-base py-2 flex-nowrap whitespace-nowrap">
                        <div className="flex flex-col font-bold">
                            {office}
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        {
                            feed.partisans.map((partisan, i) => {
                                if (partisan.office !== office) {
                                    return null;
                                }
                                return (
                                    <div key={i} className="flex flex-col justify-between">
                                        <PartisanLine partisan={partisan} status />
                                    </div>
                                )
                            })
                        }
                    </div>
                    </div>
                )
            })
        } else {
            return null;
        }
    }

    return(
        <>
         <Galea 
            title="Speeqr - a petitioning system for better governance"
            description="A platform for mobilizing democratic action and better governance."
            url={process.env.REACT_APP_DOMAIN}
        />
        <RecordEvent hitType='pageview' page={window.location.pathname} title='Feed' />
        <div className="flex flex-col items-center gap-4 m-4">
            <div className="w-full sm:w-8/12 flex flex-row justify-between">
                <div className={`text-2xl cursor-pointer ${feedOcd?.length === 0 && `underline-offset-4 underline`}`}
                    onClick={() => setFeedOcd('')}>
                    Latest
                </div>
                <div className={`text-2xl cursor-pointer text-center ${feedOcd === 'local' && `underline-offset-4 underline`}`} 
                    onClick={() => setFeedOcd('local')}>
                    Local
                </div>
                <div className={`text-2xl cursor-pointer text-center ${feedOcd === 'state' && `underline-offset-4 underline`}`} 
                    onClick={() => setFeedOcd('state')}>
                    State
                </div>
                <div className={`text-2xl cursor-pointer text-center ${feedOcd === 'country' && `underline-offset-4 underline`}`} 
                    onClick={() => setFeedOcd('country')}>
                    National
                </div>
            </div>
            <div className="w-full sm:w-8/12">
                <div className="flex sm:flex-row gap-4 flex-col-reverse">
                    <div className="flex flex-col sm:w-4/5">
                    {
                        feed?.items ? Object.values(feed.items).map((item, i) => {
                            if (item.type === 'post') {
                                return(
                                    <PostCard 
                                        key={i}
                                        post={item}
                                        isSignedIn
                                    />
                                )
                            } else if (item.type === 'proposal') {
                                return(
                                    <PropCard 
                                        key={i}
                                        proposal={item}
                                        loader={loader}
                                        isSignedIn
                                    />
                                )
                            } else {
                                return null;
                            }
                        }) : <Loader loader='feed'/>
                    }
                    {
                        loader?.feed && feed?.items && (
                            <Loader loader='feed'/>
                        )
                    }
                    {renderLoadMoreButton()}
                    </div>
                    <div className="sm:flex flex-col sm:w-1/5 hidden">
                        <div>
                            {renderPartisans()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BottomNav />
        </>
    )
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        proposals: state.proposals,
        posts: state.posts,
        loader: state.loader,
        feed: state.feed,
        partisan: state.partisan
    }
}

export default connect(mapStateToProps, {
    fetchProposalsByAddress, fetchProposals, fetchPostsByAddress, fetchPosts, fetchFeed, clearFeed
})(Feed); 