export const AUTH_USER = 'AUTH_USER';
export const LOG_OUT = 'AUTH_USER';

export const SET_LOADER = 'SET_LOADER';

export const FETCH_PROPOSALS = 'FETCH_PROPOSALS';
export const FETCH_PROPOSAL = 'FETCH_PROPOSAL';
export const FETCH_PARTISAN_PROPOSALS = 'FETCH_PARTISAN_PROPOSALS';
export const UPDATE_PROPOSAL = 'UPDATE_PROPOSAL';
export const DELETE_PROPOSAL = 'DELETE_PROPOSAL';

export const FETCH_POSTS = 'FETCH_POSTS';
export const FETCH_POST = 'FETCH_POST';
export const UPDATE_POST = 'UPDATE_POST';
export const DELETE_POST = 'DELETE_POST';

export const FETCH_AUTH_PARTISANS = 'FETCH_AUTH_PARTISANS';
export const SET_USER_PARTISAN = 'SET_USER_PARTISAN';
export const ADD_AUTH_PARTISAN = 'ADD_AUTH_PARTISAN';

export const GET_PARTISAN = 'GET_PARTISAN';
export const FETCH_PARTISAN = 'FETCH_PARTISAN';
export const UPDATE_PARTISAN = 'UPDATE_PARTISAN';

export const POST_MESSAGE = 'POST_MESSAGE';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const POST_VOTE = 'POST_VOTE';

export const ADD_NOTICE = 'ADD_NOTICE';
export const DELETE_NOTICE = 'DELETE_NOTICE';
export const HIDE_NOTICE = 'HIDE_NOTICE';

export const ADD_MODAL = 'ADD_MODAL';
export const DELETE_MODAL = 'DELETE_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const GET_PLATFORMS = 'GET_PLATFORMS';
export const GET_PLATFORM = 'GET_PLATFORM';
export const UPDATE_PLATFORM = 'UPDATE_PLATFORM';

export const FETCH_PARTISAN_PLATFORMS = 'FETCH_PARTISAN_PLATFORMS';
export const DELETE_PARTISAN_PLATFORM = 'DELETE_PARTISAN_PLATFORM';
export const UPDATE_PARTISAN_PLATFORM = 'UPDATE_PARTISAN_PLATFORM';
export const UPDATE_PARTISAN_PLATFORM_PROPOSALS = 'UPDATE_PARTISAN_PLATFORM_PROPOSALS';

export const SEARCH_ALIAS = 'SEARCH_ALIAS';

export const FETCH_FEED = 'FETCH_FEED';
export const FETCH_ITEM = 'FETCH_ITEM';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';

export const CLEAR_FEED = 'CLEAR_FEED';